import tafe from '../../images/tafe.png'
import arch from '../../images/arch.png'
import geco from '../../images/geco.png'
import belleza from '../../images/belleza.png'
import effica from '../../images/effica.png'
import foresight from '../../images/foresight.png'
import venba from '../../images/venba.png'
import dynamic from '../../images/dynamic.png'

const TrainerComponent = () =>{
    return(
        <>
            <div className="qtTrainer">
                <div className="container">
                    <div className="qtTrainerHeader">
                        <h3>Our Instructors</h3>
                        From the various reputed companies
                    </div>
                    <div className="qtTrainerContent">
                        <div className="row">
                            <div className="col-md-3">
                                <div className="qtTrainerContents">
                                        <img src={tafe} alt="QTree Trainers"/>
                                </div>
                            </div>

                            <div className="col-md-3">
                                <div className="qtTrainerContents">
                                        <img src={arch} alt="QTree Trainers"/>
                                </div>
                            </div>

                            <div className="col-md-3">
                                <div className="qtTrainerContents">
                                        <img src={geco} alt="QTree Trainers"/>
                                </div>
                            </div>

                            <div className="col-md-3">
                                <div className="qtTrainerContents">
                                        <img src={belleza} alt="QTree Trainers"/>
                                </div>
                            </div>
                        </div>
                        <div className='clearfix'>
                        </div>
                        <div className="row">
                            <div className="col-md-3">
                                <div className="qtTrainerContents">
                                        <img src={effica} alt="QTree Trainers"/>
                                </div>
                            </div>

                            <div className="col-md-3">
                                <div className="qtTrainerContents">
                                        <img src={foresight} alt="QTree Trainers"/>
                                </div>
                            </div>

                            <div className="col-md-3">
                                <div className="qtTrainerContents">
                                        <img src={venba} alt="QTree Trainers"/>
                                </div>
                            </div>

                            <div className="col-md-3">
                                <div className="qtTrainerContents">
                                        <img src={dynamic} alt="QTree Trainers"/>
                                </div>
                            </div>
                        </div>
                       
                    </div>
                </div>
            </div>
        </>
    )
}
export default TrainerComponent;