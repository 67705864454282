import { useState,useEffect} from "react"
import parse from 'html-react-parser';
import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'

const CourseFutureComponent=(props)=>{

    const[enroll,setEnroll] = useState([]);
    const icon = { 0: "fa fa-line-chart", 1: "fa fa-building", 2: "fa fa-expand" };

    const getAllData = () => {
        let URL = window.API_URL+"course/getCourseDetails/"+props.info.id;
        fetch(URL, {
          
          method: "get",
          headers: {
			'TXNTYPE': 'WHY_ENROLL',
		},
        })
        .then(response => {return response.json()}
        )
        .then(data => 
          {
           
            setEnroll(data)
        }
        )
        .catch(err => console.log(err))
      }

      const setData = () =>{
            if(enroll.length > 0)
            {
                return(
                    <div className="row">
                            {enroll.map((course,index) => (
                                    <div className="col-md-4" key={index}>
                                    <div className="qtCourseContents">
                                        <div className="qtFutureIcon">
                                            <i className={icon[index]} aria-hidden="true"></i>
                                        </div>
                                        <div className="qtCouContent">
                                            {parse(course.description)}
                                        </div>
                                    </div>
                                </div>
                             ))}
                            </div>
                )
            }
            else{
                return(
                    <div className="row">
                        {Array.from(Array(3), (e, i) => {
                            return(
                                <div className="col-md-4" key={i}>
                                <div className="qtCourseContents">
                                    <div className="qtFutureIcon">
                                        <Skeleton variant="circle" width={80} height={80}/>
                                    </div>
                                    <div className="qtCouContent">
                                        <Skeleton variant="text" count={3}/>
                                    </div>
                                </div>
                            </div>

                            )
                        })
                        }
                    </div>
                )
            }
      }
    
      useEffect(() => {
        getAllData()
      }, [])

    return(
        <>
            <div className="qtCourseFuture">
                <div className="container">
                    <div className="qtTitle">
                        <h2>Why Enroll for {props.info.course_tile}?</h2>
                    </div>
                    <div className="qtCourseContent">
                        
                        {
                            setData()
                        }

                        {/* {enroll.length > 0 && (
                            <div className="row">
                            {enroll.map((course,index) => (
                                    <div className="col-md-4" key={index}>
                                    <div className="qtCourseContents">
                                        <div className="qtFutureIcon">
                                            <i class={icon[index]} aria-hidden="true"></i>
                                        </div>
                                        <div className="qtCouContent">
                                            <p>{parse(course.description)}</p>
                                        </div>
                                    </div>
                                </div>
                             ))}
                            </div>
                         )} */}



                        </div>
                    </div>
                </div>
            
        </>
    )
}
export default CourseFutureComponent