import CourseHeader from "./header";
import FooterComponent from "../footer";
import TrueFooterComponent from "../TrueFooter";
import CountComponent from "../numbers";
import CourseFutureComponent from "./future";
import CertificateComponent from "./certificate";
import CurriculamComponent from "./curriculam";
import SuccessStory from "./successstory";
import DesignationComponent from "./designation";
import TrainingTiming from "./trainingtime";
import WhyCubicComponent from "./whycubic";
import ToolsComponent from "./tools";
import ExplainComponent from "./explain";
import FAQComponent from "./faq";
import ProjectComponent from "./project";
import ContactComponent from "./contact";
import { useLocation } from "react-router-dom";
import HeaderComponent from "../header";
import { useState,useEffect, useRef } from "react";
import ReCAPTCHA from "react-google-recaptcha";
import TestimonialComponent from "../testimonial";

const CourseComponent = (props) =>{
  

  const location = useLocation();
  const recaptchaRef = useRef(null);
  const[stateObject,setStateObject] = useState({
    fetched:false,
    id:0,
    fee:0,
    course_tile:null,
    course_description:null,
    video_url:null,
    broucher_url:null,
    can_buy:1,
  })


  useEffect(()=>{
    
    
    if(location.state  && !stateObject.fetched)
    {
     
        console.log("loaded first");
         setStateObject({
          ...stateObject,
          fetched:true,
          id:location.state.id,
          fee:location.state.fee,
          course_tile:location.state.course_tile,
          course_description:location.state.course_description,
          video_url:location.state.video_url,
          broucher_url:location.state.broucher_url,
          can_buy:location.state.can_buy,
         })
    }
    
  },[stateObject,location.state])

  useEffect(()=>{
   
    
    if(!location.state  && !stateObject.fetched)
    {
      console.log("loaded second");
      const pathname = window.location.pathname;
      const slug = pathname.substring(pathname.lastIndexOf('/') + 1);
      let URL = window.API_URL+"course/"+slug;
          fetch(URL).then((response) => {
          return response.json()
          }).then((res) => 
          {
             
             setStateObject({
              ...stateObject,
              fetched:true,
              id:res[0].id,
              fee:res[0].fee,
              course_tile:res[0].course_tile,
              course_description:res[0].course_description,
              video_url:res[0].video_url,
              broucher_url:res[0].broucher_url,
              can_buy:res[0].can_buy,
             })
          })
    }

  },[stateObject,location.state])
 

   
    return(
        
          stateObject.fetched && (
            <div>
            <HeaderComponent isHome="false"/>
            <ContactComponent/>
           
            <CourseHeader info={stateObject} captchaRef={recaptchaRef}/>
            
  
  
            <TrainingTiming info={stateObject} captchaRef={recaptchaRef}/>
            <CourseFutureComponent info={stateObject}/>
            
            <DesignationComponent info={stateObject}/>
            <WhyCubicComponent info={stateObject}/>
            <ToolsComponent info={stateObject}/>
  
    
            <CurriculamComponent info={stateObject} 
            title={stateObject.course_tile} 
            description={stateObject.course_description} captchaRef={recaptchaRef}/>
            <ExplainComponent info={stateObject} />
            <ProjectComponent info={stateObject} />
            <CertificateComponent info={stateObject}/>
            <FAQComponent info={stateObject}/>
            {/* <SuccessStoryComponent/> */}
            {/* <SuccessStory info={stateObject} 
            title={stateObject.course_tile} 
            description={stateObject.course_description}/> */}
            <TestimonialComponent/>
            
            <CountComponent/>

            
            <FooterComponent/>
            <TrueFooterComponent/>
            <ReCAPTCHA
                                        ref={recaptchaRef}
                                        className='googleRecaptcha'
                                        size="invisible"
                                        sitekey={process.env.REACT_APP_RECAPTCHA_KEY}
                                        // onChange = {(response)=>captchaOnChange(response)}
                                        
                                    />
          </div>
          )

          
        
  
    )
    
   
}
export default CourseComponent;