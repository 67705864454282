import { Link } from 'react-router-dom';
import banner from '../../qtreebanner.png'
import BannerBottom from './bannerbottom';

const BannerComponent = () =>{
    return(
        
        <>
        
            <div className='mainBanner'>
                {/* <img src={banner} alt="Qtree banner"/> */}
                <div className='qtBannerTop'>
                <div className='container qtBannerCont'>
                   
                        <div className='qtTrueBanner'>
                            <div className='qtBannerHeads'>
                            <div className='qtBannerHead wow fadeInLeft animate animated'>
                            Learn Anything and Everything Virtually
                            </div>
                            <div className='qtBannerSubHead wow fadeInLeft animate animated'>
                            with our Expert Trainers
                            </div>

                            <div className='qtBannerSubHead wow fadeInLeft animate animated'>
                            <h1>Cadbench Online Training Institute</h1>
                            </div>
                            
                            </div>
                            <div className='qtBannerContent wow fadeInLeft animate animated'>
                            An ideal way to get familiar with the latest Technologies
                            </div>
                            <div className='qtBannerLink wow fadeInLeft animate animated'>
                                <Link to="/courses">Upskill Now</Link>
                            </div>

                        </div>

                </div>
                </div>
            </div>

            <BannerBottom/>

            </>
        
        
    )
}

export default BannerComponent;