import certs from '../../images/cert/certficate.jpg'
import {
    Accordion,
    AccordionItem,
    AccordionItemHeading,
    AccordionItemButton,
    AccordionItemPanel,
} from 'react-accessible-accordion';
import 'react-accessible-accordion/dist/fancy-example.css';
import { useState,useEffect } from 'react';
import parse from 'html-react-parser';
import CertificateModal from '../modal/CertificateModal';
import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'
const CertificateComponent = (props) =>{
    const[cert,setCert] = useState([]);
    const icon = { 0: "fa fa-line-chart", 1: "fa fa-building", 2: "fa fa-expand" };
    const [isCertShow,setCertShow] = useState(false)
    const showCerts = () => {
        setCertShow(true)
    }

    const closeCerts = () => {
        setCertShow(false)
    }

    const getAllData = () => {
        let URL = window.API_URL+"course/getCourseDetails/"+props.info.id;
        fetch(URL, {
          
          method: "get",
          headers: {
			'TXNTYPE': 'CERTIFICATIONDETAILS',
		},
        })
        .then(response => {
            return response.json()
        }
        )
        .then(data => 
          {
            
            setCert(data)
        }
        )
        .catch(err => console.log(err))
      }

      const setData = () => {
            if(cert.length > 0)
            {
                return (
                    cert.map((course,index) => (

                        <AccordionItem key={index}>
                        <AccordionItemHeading>
                            <AccordionItemButton>
                                {course.title}
                            </AccordionItemButton>
                        </AccordionItemHeading>
                        <AccordionItemPanel>
                            <div className="qtTitleAccordian">
                                <ul>
                                    {parse(course.description)}
                                </ul>
                            </div>
                        </AccordionItemPanel>
                        </AccordionItem>    

                 ))
                )
            }
            else{
                return(
                    <div className='sPadding'>
                         <Skeleton variant="text" count={10}/>
                    </div>
                )
            }
      }
    
      useEffect(() => {
        getAllData()
      }, [])
    return(
        <>
            <CertificateModal cert={certs} isShow={isCertShow} isHide = {()=>setCertShow(false)}/>
            <div className="qtCertificate">
                <div className="container">
                    <div className="qtTitle alignLeft">
                        <h2>{props.info.course_tile} Certification</h2>
                    </div>
                    <div className='qtCertsMainContent'>
                    <div className="row">
                        

                        <div className="col-md-8">
                            <div className="qtCertContent text_align_left">

       
                           
                            <Accordion  allowZeroExpanded="true">
                                {
                                    setData()
                                }
                            
                            </Accordion>
                         

                           
                            </div>
                        </div>

                        <div className="col-md-4">
                            <div className='qtCertsSample' onClick={()=>showCerts()}>
                                <img src={certs} alt="Cadbench" width="100%"/>
                                <div className='clearfix'></div>
                                <div className="qtCourseButtonsFull">
                               
                                
                            </div>
                            </div>
                        </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
export default CertificateComponent