
import Modal from 'react-bootstrap/Modal';
import Container from 'react-bootstrap/Container';
import { WhatsappShareButton, FacebookShareButton, TwitterShareButton, LinkedinShareButton } from 'react-share';
import { WhatsappIcon, FacebookIcon, TwitterIcon, LinkedinIcon } from 'react-share';
import { useState } from 'react';
import copy from "copy-to-clipboard";

const ShareComponent = (props) =>{
    const [copyText, setCopyText] = useState('');
    const URL = window.location.href;
    const handleClose = () => {
        copy('');
        setCopyText('');
        props.setIsCloseShare(false);
    };

    const handleCopyText = (text) => {
        copy(props.link);
        setCopyText(text);
    }    

    return(
        <Modal show={props.isshow}
      {...props}
      size="md"
      aria-labelledby="contained-modal-title-vcenter"
      centered
       onHide={handleClose}
    >
      <Modal.Header closeButton>
          <Modal.Title>Share with the world</Modal.Title>
    </Modal.Header>
      <Modal.Body className='show-grid'>
      
        
            <div className='shareMain'>
                <div className='row'>

                    <div>
                        {
                            copyText.length > 0 &&
                            (
                                <div className='copyText'>
                                    Copied
                                </div>
                            )
                        }
                    </div>

                    <div className='col-md-10'>
                        <div className='shareLink'>
                        {props.link}
                        </div>
                    </div>
                    <div className='col-md-2'>
                        <div className='copyShareLink' onClick={() => handleCopyText(props.link)}>
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 469.3 469.3"><path fill="#0052cc" d="M330.7 0h-256C51.1 0 32 19.1 32 42.7v298.7h42.7V42.7h256V0z"></path><path fill="#0052cc" d="M394.7 85.3H160c-23.6 0-42.7 19.1-42.7 42.7v298.7c0 23.6 19.1 42.7 42.7 42.7h234.7c23.6 0 42.7-19.1 42.7-42.7V128c-.1-23.6-19.2-42.7-42.7-42.7zm0 341.4H160V128h234.7v298.7z"></path></svg>
                        </div>
                    </div>
                </div>
            </div>

            <div className='socialShareIcon'>

            <h4>Share With</h4>

             <ul>
                <li>
                <WhatsappShareButton url={props.link}>
                <WhatsappIcon type="button" size={40} round={true} />
                </WhatsappShareButton>
                </li>  
                <li>
                <FacebookShareButton url={props.link}>
                <FacebookIcon type="button" size={40} round={true}/>
                </FacebookShareButton>
                </li> 
                <li>
                <TwitterShareButton url={props.link}>
                <TwitterIcon type="button" size={40} round={true}/>
                </TwitterShareButton>
                </li> 
                <li>
                <LinkedinShareButton url={props.link}>
                <LinkedinIcon type="button" size={40} round={true}/>
                </LinkedinShareButton>  
                </li>  
             </ul>   
           
            </div>
            
        
      </Modal.Body>
    </Modal>
    )
}
export default ShareComponent