import contactImg from "../../../images/consulting.png"
import contact from "../../../images/contact"
const ContactBannerComponent = (props) => {
    return(
        <div className='qtContactHeader'>
            {/* <img src={headerImage} width='100%' alt='Cadbench'/> */}
            <div className='container'>
            <div className="row">
            <div className="col-md-7">    
            <div className='qtContactText'>
                {props.title}
            </div>
            </div>
            <div className="col-md-5">
                <div className="qtContactImg">
                    <img src={contact} alt="cadbench" width="90%"/>
                </div>
            </div>
            </div>
            </div>
            
        </div>
    )
}
export default ContactBannerComponent