import Modal from 'react-bootstrap/Modal';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import courseimg from '../../images/course.gif'
import LoadingComponent from '../loading';
import { useState } from 'react';
import { Formik,Form,Field,ErrorMessage } from 'formik';
import * as Yup from 'yup'
import TextError from '../error/TextError';
const CourseApplyModal = (props) =>{

  const[isLoading,setLoading] = useState(false);

  const initialFormValues = {
    name:'CUBIKCADD',
    email:'',
    mobile:''
  }
  const phoneRegExp = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/ 
  const validationSchema = Yup.object(
    {
        name:Yup.string().required('Required!'),
        email:Yup.string().email('Invalid email format!').required('Required!'),
        mobile: Yup.string().required('Required').matches(phoneRegExp, 'Mobile Number is not valid').min(10),
    });

  const handleSubmit = async(values) => 
  {
      setLoading(true)
      const token = await props.captchaRef.current.executeAsync();
      var myHeaders = new Headers();
      const datas = new FormData();
      datas.append("name", values.name);
      datas.append("email", values.email);
      datas.append("mobile", values.mobile);
      datas.append("g-recaptcha-response",token);
      datas.append("enquiry_for","LIKE_COURSE");
      let url = window.API_URL+"enquiry";
      fetch(url, {
          method: "POST",
          body: datas,
          headers: myHeaders,
        })
        .then(response => response.json()
        )
        .then(data => 
          {
            
            if(data[0]['data']['success'] === 1)
            {
              props.onSuccessSubmit()
            }
            else{
             
            }
            setLoading(false)
        }
        )
        .catch(err => {
             
              setLoading(false)
        })
  }

    return(
        <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      
      <LoadingComponent isShow = {isLoading}/>  
      <Modal.Body className='show-grid'>
        <Container>
            <Row>
                <Col xs={12} md={6} className='qtModalAppLeft'>
                    <div>
                        <img src={courseimg} alt="Cadbench" width="100%"/>
                    </div>
                </Col>
                <Col xs={12} md={6}>
                        <div className='qtModalAppRight'>
                        <div className="modal-title h4">Upskill Your Knowedge</div>
                            <div className='clearfix'></div>
                            <Formik
                                initialValues={initialFormValues}
                                validationSchema={validationSchema}
                                onSubmit={handleSubmit}
                                >
                                  <Form>

                                  <div className="col-md-6 textAlignLeft">
                                            <label>Name</label>
                                            <Field type="text" 
                                            className="form-control" 
                                            id='name'
                                            name='name'
                                            placeholder="Name"/>
                                            <ErrorMessage name='name' component={TextError}/>
                                        </div>

                                  <div className="col-md-6 textAlignLeft">
                                            <label>Email</label>
                                            <Field type="email" 
                                            className="form-control" 
                                            id='email'
                                            name='email'
                                            placeholder="Email"/>
                                            <ErrorMessage name='email' component={TextError}/>
                                        </div>
                                
                                  <div className="col-md-6 textAlignLeft">
                                            <label>Phone Number</label>
                                            <Field type="text" 
                                            className="form-control" 
                                            id='mobile'
                                            name='mobile'
                                            maxLength="12" 
                                            placeholder="Mobile Number"/>
                                            <ErrorMessage name='mobile' component={TextError}/>
                                        </div>
                                
                                <div className="qtCourseButtons">
                                <button className="submit">Submit</button>
                                &nbsp;&nbsp;
                                <button type="button" className="closeModal" onClick={props.onHide}>Close</button>
                            </div>
                            </Form>
                                </Formik>
                        </div>
                </Col>
            </Row>
        </Container>
      </Modal.Body>
      
    </Modal>
    )
}
export default CourseApplyModal