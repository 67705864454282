import logo from '../../logos.png'
const InfoComponent = () => {
    return(
        <div>

            <div className="qtInfoBox">
                <div className="container">
                    <div className="qtInfoContent">
                        <div className="row">
                            <div className="col-md-9">
                                <div className="qtInfoMainContent">
                                9/10 of our learners achieve their learning objectives after successful course completion*
                                </div>
                            </div>
                            <div className="col-md-3">
                                <div className='qtInfoImage'>
                                    <img src={logo} alt="Qtree Tech" width="230px" height="100px"/>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    );
}
export default InfoComponent;