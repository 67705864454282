import { useCallback, useState } from "react";
import useRazorpay from "react-razorpay";
import logo from '../../logos.png'

export default function TestPayment() {
  const Razorpay = useRazorpay();

  const createOrder = async () =>{
    const datas = new FormData();
            datas.append("course_id", 1234);
            datas.append("fee", 500000);
            datas.append("title", "React JS UI Development");
            datas.append("user_email", "manibharath159@gmail.com");
            const settings = {
                method: 'POST',
                body:datas,
            };
            try {
                const fetchResponse = await fetch("http://127.0.0.1:8000/api/generateOrderId", settings);
                const data = await fetchResponse.json();
                return data;
            } catch (e) {
                return e;
            }   
  }

  const handlePayment = useCallback(async () => {
    const order = await createOrder();
    const options= {
      key: "rzp_test_8AmKToYlWgWlLC",
      amount: "3000",
      currency: "INR",
      name: "Payment Testing",
      description: "Description",
      image: logo,
      order_id: order.id,
      handler: (res) => {
        alert(res.razorpay_payment_id);
        alert(res.razorpay_order_id);
        alert(res.razorpay_signature);
      },
      prefill: {
        name: "Manibharathi",
        email: "manibharath159@gmail.com",
        contact: "9944033729",
      },
      notes: {
        address: "Razorpay Corporate Office",
      },
      theme: {
        color: "#3399cc",
      },
    };

    const rzpay = new Razorpay(options);
    rzpay.on("payent.failed",function(response){
        alert("Payment Failed");
    });

    rzpay.on("payment.success",function(response){
        alert("Payment Success");
    });
    rzpay.open()
  }, [Razorpay]);

  return (
    <div className="Apps">
      <button onClick={handlePayment}>Click</button>
    </div>
  );
}