import React from "react";
import { useState,useEffect } from "react";
import { useSelector,useDispatch } from 'react-redux';
import CourseApplyModal from "./courseapplymodal";
import ModalDialog from "../login/ModalDialog";
import AddCart from "../account/addcart";
import { cartItem } from "../actions";
import LoadingComponent from "../loading";
import wishlist from "../../images/wishlist.png";
import awishlist from "../../images/wishlists.png";
import share from "../../images/share.png";
import ShareComponent from "../share";
import SeoComponent from "../helmet/SeoComponent";
import EnquiryModal from "./enquirymodal";
import { saveAs } from 'file-saver'
import Swal from 'sweetalert2'
import { toast} from 'react-toastify';
const CourseHeader = (props) =>{
    
    const cart = useSelector((state)=>state.LoginLogout);
    const dispatch = useDispatch();
    const nf = new Intl.NumberFormat();
    const [isLoading,setLoading] = useState(false);
    const [show, setShow] = useState(false);
    const [modalShow, setModalShow] = React.useState(false);
    const [encModalShow, setEncModalShow] = React.useState(false);
    const [encMode,setEncMode] = React.useState("");
    const { usersData, isLogin } = useSelector((state) => state.LoginLogout);
    const [cartShow,setCartShow] = useState(false);
    const [isWishList,setIsWishList] = useState(false);
    const [isShowShare,setIsShowShare] = useState(false);
    const [link,setLink] = useState("");
    const [isVideo,setIsVideoEnabled] = useState(false);
    const videoURL = props.info.video_url
    const broucherUrl = props.info.broucher_url;
    const handleShareClose = () =>{
        setIsShowShare(false);
    }


    const downloadBrouchur = () => {
        setEncMode("DOWNLOAD_BROUCHER");
        setEncModalShow(true);
        //saveAs(broucher_url, 'image.jpg') // Put your image URL here.
    }

    const convertCurrency = (amount) => {
    
        if(cart.currency === "USD")
        {
            let fee = Math.round(amount*cart.currencyValue);
            return(
              nf.format(fee)
            )
        }
        else{
         
          return amount
        }
      }

    const generateLink = () => {
        setLoading(true)
        const data = new FormData();
        data.append("link", window.location.href);
            let URL = window.API_URL+"generateShortLink";
            fetch(URL, {
              method: "POST",
              body: data,
            })
            .then(response => response.json()
            )
            .then(data => 
              {
                    if(data.success === 1)
                    {
                        setLink(window.SITE_URL+data.message.code);
                        setIsShowShare(true);
                    }
                    else{

                    }
                  setLoading(false);
              }
            )
            .catch(err => console.log(err))
    }

    const sucessVideoForm = (mode) =>{
        if(encMode === "LIKE_VIDEO")
        {
            setEncModalShow(false);
            setIsVideoEnabled(true);
        }
        if(encMode == "DOWNLOAD_BROUCHER")
        {
            setEncModalShow(false);
            saveAs(window.IMG_URL+broucherUrl, broucherUrl)
            //broucherUrl = "";
        }
        setEncMode("");
    }


    const handleCartClose = ()=>{
        setCartShow(false);
    }

    const handleClose = () => 
    {
        setShow(false);
    }
    

    const getWishListStatus = () => {
        if(isLogin === true)
        {
            let URL = window.API_URL+"getWishlistByUser/"+usersData.id;
                fetch(URL).then((response) => {
                return response.json()
                }).then((res) => {
                    if(res.length > 0)
                    {
                        setIsWishList(true);
                    }
                    
                })
        }
    }

    const addToWishList = () => {
        if(isLogin === true)
        {   
            
            setLoading(true);
            const data = new FormData();
            data.append("course_id", props.info.id);
            data.append("user_id", usersData.id);
            let URL = window.API_URL+"addToWishList";
            fetch(URL, {
              method: "POST",
              body: data,
            })
            .then(response => response.json()
            )
            .then(data => 
              {
                
                if(data[0]['data']['success'] === 1)
                    {
                        if(data[0]['data']['message'] == "Inserted")
                            setIsWishList(true);
                        else
                            setIsWishList(false);    
                    }
                    else if(data[0]['data']['success'] === 0){
                        toast.error(data[0]['data']['message'], {
                            position: toast.POSITION.TOP_RIGHT
                          });    
                    }
                    else{
                        toast.error("Something went wrong!, Please try again!", {
                            position: toast.POSITION.TOP_RIGHT
                          });    
                    }
                    setLoading(false);
                }
            )
            .catch(err => console.log(err))
        }
        else
        {
            setShow(true)
        }
    }


    if(isLogin === true && show === true)
    {
        setShow(false)
    }
    const buyThisCourse = () => {

        
        if(isLogin === true)
        {   
            setLoading(true);
            var myHeaders = new Headers();
            myHeaders.append("Authorization", 'Bearer '+usersData['token']);
            const data = new FormData();
            data.append("course_id", props.info.id);
            data.append("fee", props.info.fee);
            data.append("user_id", usersData.id);
            let URL = window.API_URL+"addToCart";
            fetch(URL, {
              method: "POST",
              body: data,
              headers: myHeaders,
            })
            .then(response => response.json()
            )
            .then(data => 
              {
                if(data[0]['data']['success'] === 1)
                    {
                        dispatch(cartItem(cart.usersData,cart.cartCount+1,cart.currency,cart.currencyValue));
                        setCartShow(true);
                    }
                    else if(data[0]['data']['success'] === 2){
                        //alert(data[0]['data']['message']) ;  
                        Swal.fire({
                            title: 'Email Verification',
                            text: "You haven't verified your email yet. Go to your profile and do verify.",
                            icon: 'warning',
                            allowOutsideClick: false,
                            showCancelButton: false,
                            confirmButtonColor: '#2D3748',
                            cancelButtonColor: '#d33',
                            confirmButtonText: 'Go to My Profile'
                              }).then((result) => {
                                if (result.isConfirmed) {
                                  window.location.href=window.SITE_URL+"myprofile"
                                }
                              })     
                    }
                    else if(data[0]['data']['success'] === 0)
                    {
                        toast.error(data[0]['data']['message'], {
                            position: toast.POSITION.TOP_RIGHT,
                            toastId: 'error1',
                          });  
                    }
                    else{
                        toast.error("Something went wrong!, Please try again!", {
                            position: toast.POSITION.TOP_RIGHT
                          });   
                    }
                    setLoading(false);
                }
            )
            .catch(err => console.log(err))
        }
        else
        {
            setShow(true)
        }
    }

    useEffect(() => {
        getWishListStatus();
      }, [])

      const onVideoOverLayClick = () => {
        //alert("Video Clicked");
        setEncMode("LIKE_VIDEO");
        setEncModalShow(true);
      }

    return(
        <>

        <SeoComponent title={props.info.course_tile} description={props.info.course_tile} keywords={props.info.keywords}/>
        <ShareComponent isshow = {isShowShare} setIsCloseShare={handleShareClose} link={link}/>
        <LoadingComponent isShow = {isLoading}/>
        <ModalDialog isShow={show} isClose={handleClose}/>
        <AddCart isshow={cartShow} isclose={handleCartClose}/>
            <div className="qtCourseHeader">

                <div className="container">
                    <div className="row">
                        <div className="col-md-7">
                            <div className="qtCourseTitle">
                                <h1>{props.info.course_tile}</h1>
                            </div>
                            <div className="qtCourseIcons">
                                <ul>
                                <li><i className={(cart.currency === "INR")?"fa fa-rupee":"fa fa-usd"}></i> <span>
                                    {
                                        convertCurrency(props.info.fee)    
                                        // nf.format(props.info.fee)
                                    }</span></li>
                                    &nbsp;&nbsp;&nbsp;&nbsp;
                                    <li><i className="fa fa-graduation-cap" aria-hidden="true"></i> <span>501 Students</span></li>
                                    <li><i className="fa fa-star" aria-hidden="true"></i> <span>5 Star Ratings</span></li>
                                </ul>
                            </div>
                            
                        </div>
                        <div className="col-md-5">
                            <div className="qtShareContacts">
                                    <ul>
                                        <li>Have quries? Ask us<br/>
                                        <span><svg xmlns="http://www.w3.org/2000/svg" width="16.523" height="16.491" viewBox="0 0 16.523 16.491"><path data-name="Path 24546" d="M53.8,12.05l-2.3-2.3a1.569,1.569,0,0,0-2.2,0,2.092,2.092,0,0,0-.4.6,1.566,1.566,0,0,1-1.8,1,7.3,7.3,0,0,1-4.3-4.3,1.431,1.431,0,0,1,1-1.8,1.584,1.584,0,0,0,1-1.9,2.092,2.092,0,0,0-.4-.6L42.1.45a1.569,1.569,0,0,0-2.2,0l-1.6,1.6c-1.6,1.6.2,6,4,9.8,3.9,3.9,8.2,5.7,9.8,4l1.6-1.6a1.485,1.485,0,0,0,.1-2.2" transform="translate(-37.72)"></path></svg>
                                        +91 9500024134</span>
                                        </li>
                                        <li>



                                            <div className="qtShareIcons"
                                            onClick={
                                                ()=>addToWishList()}>
                                            {/* <svg xmlns="http://www.w3.org/2000/svg" width="18.984" height="16.89" viewBox="0 0 18.984 16.89"><path d="M9.492 16.89a1.113 1.113 0 0 1-.734-.276c-.767-.671-1.506-1.3-2.159-1.857a40.148 40.148 0 0 1-4.716-4.427A7.066 7.066 0 0 1 0 5.705a5.933 5.933 0 0 1 1.506-4.052A5.1 5.1 0 0 1 5.3 0a4.775 4.775 0 0 1 2.986 1.03 6.1 6.1 0 0 1 1.206 1.259A6.1 6.1 0 0 1 10.7 1.03 4.775 4.775 0 0 1 13.681 0a5.105 5.105 0 0 1 3.8 1.653 5.933 5.933 0 0 1 1.506 4.052A7.065 7.065 0 0 1 17.1 10.33a40.139 40.139 0 0 1-4.713 4.424 19.265 19.265 0 0 1-2.895 2.136z" opacity="0.379"></path></svg> */}
                                                {
                                                    isWishList && (
                                                        <img src={awishlist} width="100%" alt="Cadbench Wish List"/>
                                                    )

                                                }
                                                {
                                                    !isWishList &&(
                                                        <img src={wishlist} width="100%" alt="Cadbench Wish List"/>
                                                    )
                                                }
                                            </div>

                                        </li>
                                        <li>
                                            <div className="qtShareIcons" onClick={()=>generateLink()}>
                                            <img src={share} width="100%" alt="Cadbench Wish List"/>
                                            {/* <svg xmlns="http://www.w3.org/2000/svg" width="17.652" height="16.099" viewBox="0 0 17.652 16.099"><path d="M17.652 8.544L10.316.5v4.8h-1.56A8.756 8.756 0 0 0 0 14.056V16.6l.693-.759a12.431 12.431 0 0 1 9.182-4.051h.441v4.8zm0 0" opacity="0.379"></path></svg> */}
                                            </div>
                                            
                                        </li>
                                    </ul>
                            </div>
                        </div>
                    </div>

                    <div className="row">
                            <div className="col-md-7">
                            <div className="qtMainContent">
                                <p>{props.info.course_description}
</p>
                            </div>
                            <div className="qtCourseButtons">
                             {
                                props.info.can_buy == 1 && (
                                    <>
                                    <button className="submit"  onClick={() => buyThisCourse()}>Buy this Course</button>
                                    &nbsp;&nbsp;&nbsp;
                                    </>
                                    
                                    
                                )
                             }   
                            <button className="submit_download" onClick={()=>downloadBrouchur()}>Download Brouchers <i className="fa fa-download" aria-hidden="true"></i></button>
                            </div>
                            </div>
                            <div className="col-md-5">
                            <div className="qtCourseVideo">
                                {
                                    !isVideo &&
                                    (
                                    <div className="qtCourseOverlay" onClick={onVideoOverLayClick}></div>    
                                    )
                                }
                            
                                <iframe width="100%" height="315" src={videoURL} title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowFullScreen></iframe>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
            
            
            <CourseApplyModal show={modalShow}
            onHide={() => setModalShow(false)} captchaRef={props.captchaRef}
            />

            <EnquiryModal show={encModalShow} mode={encMode} onSuccessSubmit = {(mode)=>sucessVideoForm(mode)}
            onHide={() => setEncModalShow(false)} captchaRef = {props.captchaRef}
            />

          

        </>
    )
}
export default CourseHeader