import { useCallback, useState } from "react";
import useRazorpay from "react-razorpay";
import logo from '../../logos.png'
import { useSelector,useDispatch } from "react-redux";
import { cartItem } from "../actions";
import LoadingComponent from "../loading";
import { toast,ToastContainer} from 'react-toastify';
import Swal from 'sweetalert2'
import { Base64 } from 'js-base64';
import CryptoJS from 'crypto-js';
const PaymentComponent = (props) =>{
  console.log("First Voucher Id",props.voucher);
  console.log("First Voucher value",props.voucher_value);
  let myVoucherId = props.voucher;
  let myVoucherValue = props.voucher_value;
  console.log("Print Voucher Id",myVoucherId);
  console.log("Print Voucher value",myVoucherValue);
  const { usersData, isLogin,cartCount,currency,currencyValue } = useSelector((state) => state.LoginLogout);
  const[isLoading,setLoading] = useState(false);
  const dispatch = useDispatch();  
    const Razorpay = useRazorpay();

    const getKeys = async()=>{
      const formdata = new FormData();
      var myHeaders = new Headers();
      myHeaders.append("Authorization", 'Bearer '+usersData['token']);
      var requestOptions = {
        method: 'POST',
        headers:myHeaders,
      };
      try {
          const fetchResponse = await fetch(window.API_URL+"getValues", requestOptions);
          const data = await fetchResponse.json();
         
          return data;
      } catch (e) {
          return e;
      }   
    }

    const createOrder = async (fee) =>{

                const formdata = new FormData();
                formdata.append("user_id", props.userData.id);
                formdata.append("fee",fee);
                formdata.append("currency",currency);
                formdata.append("title", "Payment");
                formdata.append("user_email", props.userData.email);
                
                var requestOptions = {
                  method: 'POST',
                  body: formdata
                };
               
                try {
                    const fetchResponse = await fetch(window.API_URL+"generateOrderId", requestOptions);
                    const data = await fetchResponse.json();

                    return data;
                } catch (e) {
                    return e;
                }   
      }

      
    
      const handlePayment = useCallback(async (amount,currency) => {

        

        setLoading(true);
        let fee =  amount*100;
        /*let keys = await getKeys();
        let encKey = JSON.stringify(keys);
          if(encKey.includes('errors'))
          {
            setLoading(false);
            toast.error("Something went wrong!, Please Try Again!", {
              position: toast.POSITION.TOP_RIGHT
            });
            return
          }
        console.log("Key Data =>"+keys['data']);  
        let key = "8W0yA0rObAL+4NyBcU7IlxrV46MpndUdLjbxVCATVzY=";
        let encrypted_json = JSON.parse(Base64.decode(keys['data']));
        let decrypted = CryptoJS.AES.decrypt(
        encrypted_json.value,
        CryptoJS.enc.Base64.parse(key),
        {
          iv: CryptoJS.enc.Base64.parse(encrypted_json.iv)
        }
        );
        let rKey = JSON.parse(decrypted.toString(CryptoJS.enc.Utf8));
        console.log("Razor Pay ID =>"+rKey.RAZORPAY_KEY_ID);*/
        const order = await createOrder(fee);
        const options= {
          key: "rzp_live_FM7syes2MovkpF",
          //key:"rzp_test_tMoKs1MTilhB4P",
          amount: fee,
          currency: currency,
          name: "Course Payment",
          description: "Course Payment",
          image: logo,
          order_id: order.id,
          handler: (res) => {
            //Capture
            const data = new FormData();
            data.append("razorpay_payment_id", res.razorpay_payment_id);
            let URL = window.API_URL+"store";
            fetch(URL, {
              method: "POST",
              body: data,
            })
            .then(response => response.json()
            )
            .then(data => 
              {
                  
                  cartToOrder(res.razorpay_payment_id,res.razorpay_order_id);
              }
            )
            .catch(err => console.log(err))

            // res.razorpay_payment_id;
            // res.razorpay_order_id;
            // res.razorpay_signature;
            
          },
          "modal": {
            "ondismiss": function(){
              setLoading(false);
              toast.info("Payment Cancelled", {
                position: toast.POSITION.TOP_RIGHT
              });
            }
        },
          
          prefill: {
            name: usersData.name,
            email: usersData.email,
            contact: usersData.mobile,
          },
          notes: {
            address: "Razorpay Corporate Office",
          },
          theme: {
            color: "#3399cc",
          },
        };
    
        const rzpay = new Razorpay(options);
        rzpay.on("payent.failed",function(response){
          toast.error("Payment Failed", {
            position: toast.POSITION.TOP_RIGHT
          });
        });
    
        rzpay.on("payment.success",function(response){
          toast.success("Successfully Paid", {
            position: toast.POSITION.TOP_RIGHT
          });
        });
        rzpay.open()
      }, [Razorpay]);

      const cartToOrder = ($paymentId,$orderId) => {
        
          setLoading(true);
          const data = new FormData();
          data.append("id", props.userData.id);
          data.append("payment_ref_id", $paymentId);
          data.append("order_ref_id", $orderId);
          data.append("voucher_id",localStorage.getItem('version_vid'));
          data.append("voucher_value",localStorage.getItem('version_v_value'))
          let URL = window.API_URL+"transferCartToOrder";
          fetch(URL, {
            method: "POST",
            body: data,
          })
          .then(response => response.json()
          )
          .then(data => 
            {
                if(data.success === 1)
                {
                  localStorage.setItem('version_vid',"");
                  localStorage.setItem('version_v_value',"");
                  dispatch(cartItem(usersData,0,currency,currencyValue)); 
                 
                  Swal.fire({
                    title: 'Confirmation',
                    text: data.message +". The Order invoice has been sent to registered email.",
                    icon: 'success',
                    allowOutsideClick: false,
                    showCancelButton: false,
                    confirmButtonColor: '#2D3748',
                    cancelButtonColor: '#d33',
                    confirmButtonText: 'Go to my courses'
                      }).then((result) => {
                        if (result.isConfirmed) {
                          window.location.href=window.SITE_URL+"mycourses"
                        }
                      })
                }
                else
                {
                  toast.error("Something went wrong", {
                    position: toast.POSITION.TOP_RIGHT
                  });
                }
                setLoading(false);
            }
          )
          .catch(err => console.log(err))
      }
    
      return (
        <>
          <LoadingComponent isShow = {isLoading}/>
          <div className='cadCheckOutBtn' onClick={()=>handlePayment(props.totalAmount,props.currency)}>
           Checkout
          </div> 
        </>
        
      );
}
export default PaymentComponent