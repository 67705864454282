import { useState,useEffect } from 'react';
import {
    Accordion,
    AccordionItem,
    AccordionItemHeading,
    AccordionItemButton,
    AccordionItemPanel,
} from 'react-accessible-accordion';
import 'react-accessible-accordion/dist/fancy-example.css';
import parse from 'html-react-parser';
import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'
const FAQComponent = (props) => {

    const[faq,setFAQ] = useState([]);
    const icon = { 0: "fa fa-line-chart", 1: "fa fa-building", 2: "fa fa-expand" };

    const getAllData = () => {
        let URL = window.API_URL+"course/getCourseDetails/"+props.info.id;
        fetch(URL, {
          
          method: "get",
          headers: {
			'TXNTYPE': 'CERTFAQ',
		},
        })
        .then(response => response.json()
        )
        .then(data => 
          {
            setFAQ(data)
        }
        )
        .catch(err => console.log(err))
      }

      const setData = () => {
        if(faq.length > 0)
        {
            return (
                faq.map((course,index) => (

                    <AccordionItem key={index}>
                    <AccordionItemHeading>
                        <AccordionItemButton>
                            {course.title}
                        </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                        <div className="qtTitleAccordian">
                            <ul>
                                {parse(course.description)}
                            </ul>
                        </div>
                    </AccordionItemPanel>
                    </AccordionItem>    

             ))
            )
        }
        else
        {
            return (
                <div className='sPadding'>
                <Skeleton variant="text" count={10}/>
           </div>
            )
        }
      }
    
      useEffect(() => {
        getAllData()
      }, [])

    return(
        <>
            <div className="qtFAQ">
                <div className="container">
                <div className="qtTitle textAlignLeft">
                        <h2>Online {props.info.course_tile} Certification Training FAQ</h2>
                </div>   
                    <div className="qtFAQContent text_align_left">
                    <Accordion allowZeroExpanded="true">
                        {
                            setData()
                        }
                    </Accordion>
                    </div>
                </div>
            </div>
        </>
    )
}
export default FAQComponent;