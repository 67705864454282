import { useState } from "react";
import { useEffect } from "react";

import profile from '../../images/user.png'
import { Rating } from 'react-simple-star-rating'

import Slider from "react-slick";


const TestimonialComponent = () =>{
  const [data,setData] = useState([])

  const getAllFeedbacks = () =>{

            let url = window.API_URL+"user_feedbacks";
            

            fetch(url, {
            
            method: "get",
            })
            .then(response => {
            return response.json()
            }
            )
            .then(data => 
            {
                if(data.success)
                {
                    setData(data.data);
                }
                
            
            }
            )
            .catch(err => {
                //console.log(err)
            })
  }

  useEffect(() => {
    //setInitialCurrency()
    getAllFeedbacks()
    
  }, [])


  var settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 4,
    arrows: true,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          arrows: false,
          slidesToShow: 1,
          slidesToScroll: 1,
          dots: false,
        },
      },
      {
        breakpoint: 480,
        settings: {
          arrows: false,
          slidesToShow: 1,
          slidesToScroll: 1,
          dots: false,
        },
      },
    ],
  };

return (

    <div>

        <div className="qtTestimonials">
        <div className="qtTestHeading">
                            <h2>What People Say</h2>
                </div>
            <div className="container">
            <Slider {...settings}>
            {
                data.length > 0 && (
                    data.map((details,index)=>(
                        <div>
                      <div className="qtTestimonial">
                                <div className="qtTestProfile">
                                    <img src={window.IMG_URL+details.image} width="100px" height="100px" alt="Cadbench Testimonials"/>
                                </div>
                                <div className="qtTestContents">
                                    <h3 className="qtProfileName">{details.name}</h3>
                                    <h3 className="qtProfileCourse">{details.taken_course}</h3>
                                    <div className="qtTestimonialContents">
                                        <p>
                                        <span><i className="fa fa-quote-left" aria-hidden="true"></i></span>&nbsp;&nbsp;
                                        {details.review}
                                        </p>
                                        <Rating
                                                                                        initialValue={details.rating} readonly="true"
                                                                                            />   
                                    </div>
                                </div>
                          </div>
      </div>
                    ))
                )
            }

      
      
      
    </Slider>
                  {/* <div className="row">
                      <div className="col-md-3">
                          <div className="qtTestimonial">
                                <div className="qtTestProfile">
                                    <img src={profile} width="100px" height="100px" alt="Cadbench Testimonials"/>
                                </div>
                                <div className="qtTestContents">
                                    <h3 className="qtProfileName">John Doe</h3>
                                    <h3 className="qtProfileCourse">Rivit Courses</h3>
                                    <div className="qtTestimonialContents">
                                        <p>
                                        <span><i class="fa fa-quote-left" aria-hidden="true"></i></span>&nbsp;&nbsp;
                                        The Lorem Ipsum Company has been an integral part of our content marketing success. We have seen a notable increase in organic leads since we began using their services in 2012.
                                        </p>
                                        <Rating
                                                                                        initialValue="5" readonly="true"
                                                                                            />   
                                    </div>
                                </div>
                          </div>
                      </div>

                      <div className="col-md-3">
                          <div className="qtTestimonial">
                                <div className="qtTestProfile">
                                    <img src={profile} width="100px" height="100px" alt="Cadbench Testimonials"/>
                                </div>
                                <div className="qtTestContents">
                                    <h3 className="qtProfileName">John Doe</h3>
                                    <h3 className="qtProfileCourse">Rivit Courses</h3>
                                    <div className="qtTestimonialContents">
                                        <p>
                                        <span><i class="fa fa-quote-left" aria-hidden="true"></i></span>&nbsp;&nbsp;
                                        The Lorem Ipsum Company has been an integral part of our content marketing success. We have seen a notable increase in organic leads since we began using their services in 2012.
                                        </p>
                                        <Rating
                                                                                        initialValue="5" readonly="true"
                                                                                            />   
                                    </div>
                                </div>
                          </div>
                      </div>

                      <div className="col-md-3">
                          <div className="qtTestimonial">
                                <div className="qtTestProfile">
                                    <img src={profile} width="100px" height="100px" alt="Cadbench Testimonials"/>
                                </div>
                                <div className="qtTestContents">
                                    <h3 className="qtProfileName">John Doe</h3>
                                    <h3 className="qtProfileCourse">Rivit Courses</h3>
                                    <div className="qtTestimonialContents">
                                        <p>
                                        <span><i class="fa fa-quote-left" aria-hidden="true"></i></span>&nbsp;&nbsp;
                                        The Lorem Ipsum Company has been an integral part of our content marketing success. We have seen a notable increase in organic leads since we began using their services in 2012.
                                        </p>
                                        <Rating
                                                                                        initialValue="5" readonly="true"
                                                                                            />   
                                    </div>
                                </div>
                          </div>
                      </div>

                      <div className="col-md-3">
                          <div className="qtTestimonial">
                                <div className="qtTestProfile">
                                    <img src={profile} width="100px" height="100px" alt="Cadbench Testimonials"/>
                                </div>
                                <div className="qtTestContents">
                                    <h3 className="qtProfileName">John Doe</h3>
                                    <h3 className="qtProfileCourse">Rivit Courses</h3>
                                    <div className="qtTestimonialContents">
                                        <p>
                                        <span><i class="fa fa-quote-left" aria-hidden="true"></i></span>&nbsp;&nbsp;
                                        The Lorem Ipsum Company has been an integral part of our content marketing success. We have seen a notable increase in organic leads since we began using their services in 2012.
                                        </p>
                                        <Rating
                                                                                        initialValue="5" readonly="true"
                                                                                            />   
                                    </div>
                                </div>
                          </div>
                      </div>
                  </div> */}
            </div>

        </div>

    </div>


  
  );
}
export default TestimonialComponent