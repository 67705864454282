import { Link } from "react-router-dom"
const SocialComponent = () =>{
    return(
        <>
        <a href="tel:+919500024134" id="callPhone" className="animate animated swing infinite"></a>
<a href="https://wa.me/919500024134?text=Hi%20Cad%20Bench%20" id="callWhatsapp" target="_blank"></a>
        </>
        
    )
}
export default SocialComponent