import FooterComponent from "../footer";
import HeaderComponent from "../header";
import emailVerfied from "../../images/verify_emails.gif"
import { useSearchParams } from "react-router-dom";
const EmailFailure = () =>{
    const [searchParams, setSearchParams] = useSearchParams();
    return(
        <>
            <HeaderComponent isHome="false"/>
            <div className="cadEmail">
                <div className="cadEmails">
                    <div className="cadEmailsMain">
                <div className="cadEmailStatus">
                    Invalid Details
                </div>
                <div className="cadEmailIcon">
                    <img src={emailVerfied} alt="Cadbench"/>
                </div>
                   
                <div className="cadEmailContents">
                    Email Verification is Failed!. Please request new email verification.
                </div>
                </div>
                </div>
            </div>
           <FooterComponent/>
        </>
    )
}
export default EmailFailure