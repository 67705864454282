const BannerBottom = () =>{
    return(
        <>
        <div className='qtBanRight'>
                    <div className='qtBanRightText'>
                        <div className='bannerSkew wow headShake animate animated'><span>1000+ </span>Students are learning on Cadbench today</div>
                    
                    </div>
            </div>
        </>
    )
}
export default BannerBottom