const WhyChooseComponent = () => {
    return(
        <>
            <div className="qtWhyChoose">
            <div className="qtMainHead">
                    <div className="container">
                    <h4>Why Choose Cadbench</h4>
                    <h5>Cadbench is a live, interactive platform for CAD Software training, in outfitting characters, with flashing ways to prosperous various courses. One will help 24x7 help, so that any inquiry and questions that ascents can be explained, permitting you to learn and perform with an expanded effectiveness.
</h5>
                    </div>
                </div>

            <div className="qtWhyChooseMains">
                <div className="container">
                    <div className="row">
                        <div className="col-md-4 freePadding">
                        <div className="qtWhyChooseMain align-middle">
                            <div className="qtWhyIcon">
                                <i className="fa fa-clock-o" aria-hidden="true"></i>
                            </div>
                            <div className="qtWhyChooseHead">
                                Flexible Timing
                            </div>
                            <div className="qtWhyChooseContents">
                            Learn the latest technologies in your free time with our masters.
                            </div>
                        </div>
                        </div>

                        <div className="col-md-4 freePadding">
                        <div className="qtWhyChooseMain brLeftRight">
                            <div className="qtWhyIcon">
                            <i className="fa fa-industry" aria-hidden="true"></i>
                            </div>
                            <div className="qtWhyChooseHead">
                                Industrial Expert Trainers
                            </div>
                            <div className="qtWhyChooseContents">
                            Become a master by getting trained by our Industrial Expert Trainers
                            </div>
                        </div>
                        </div>

                        <div className="col-md-4 freePadding">
                        <div className="qtWhyChooseMain">
                            <div className="qtWhyIcon">
                            <i className="fa fa-clock-o" aria-hidden="true"></i>
                            </div>
                            <div className="qtWhyChooseHead">
                                24 * 7 Support
                            </div>
                            <div className="qtWhyChooseContents">
                            We are always ready to help you in clearing your doubts, we have a team to clear doubts immediately.
                            </div>
                        </div>
                        </div>
                    </div>

                    <div className="hrLine"></div>

                    <div className="row">
                        <div className="col-md-4 freePadding">
                        <div className="qtWhyChooseMain align-middles">
                            <div className="qtWhyIcon">
                            <i className="fa fa-tasks" aria-hidden="true"></i>
                            </div>
                            <div className="qtWhyChooseHead">
                                Placement Assistance
                            </div>
                            <div className="qtWhyChooseContents">
                            We provide 100% job Assured world-class training to make our candidates experts in their careers
                            </div>
                        </div>
                        </div>

                        <div className="col-md-4 freePadding">
                        <div className="qtWhyChooseMain brLeftRight">
                            <div className="qtWhyIcon">
                            <i className="fa fa-users" aria-hidden="true"></i>
                            </div>
                            <div className="qtWhyChooseHead">
                               One-on-One Training
                            </div>
                            <div className="qtWhyChooseContents">
                            Candidates can go for one-on-one training at their flexible times.
                            </div>
                        </div>
                        </div>

                        <div className="col-md-4 freePadding">
                        <div className="qtWhyChooseMain">
                            <div className="qtWhyIcon">
                            <i className="fa fa-history" aria-hidden="true"></i>
                            </div>
                            <div className="qtWhyChooseHead">
                                Syllabus By Experts
                            </div>
                            <div className="qtWhyChooseContents">
                            The Cadbench Master programs syllabus is framed by our Industrial Expert Trainers.
                            </div>
                        </div>
                        </div>
                    </div>
                </div>
            </div>    
            </div>
        </>
    )
}
export default WhyChooseComponent;