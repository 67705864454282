const BootCampComponent = () =>{
    return(
        <div>
            <div className="qtBootCamp">
                <div className="qtMainHead">
                    <div className="container">
                    <h4>Why make us Unique</h4>
                    <h5>An immersive learning experience</h5>
                    </div>
                </div>
                <div className="qtMainContents">
                <div className="container">
                    <div className="row">
                        <div className="col-md-3">
                            <div className="qtBootCampContent">
                                <div className="qtBootIcon">
                                <i className="fa fa-id-card" aria-hidden="true"></i>
                                </div>
                                <div className="qtSecHead">
                                    <h2>Ready to Accept Newness in Technology</h2>
                                </div>
                                <div className="qtBootContent">
                                    <p>
                                    Learning the fundamentals of the technology and understanding how it works can also help in readily accepting new technology. It's essential to approach the new technology with a problem-solving mindset and see how it can benefit you and your work. Cadbench keep updating with new technologies around the world
                                    </p>
                                </div>
                            </div>
                        </div>

                        <div className="col-md-3">
                            <div className="qtBootCampContent">
                            <div className="qtBootIcon">
                                <i className="fa fa-id-card" aria-hidden="true"></i>
                                </div>
                                <div className="qtSecHead">
                                    <h2>We help you in your career needs through virtual mode irrespective of location:

</h2>
                                </div>
                                <div className="qtBootContent">
                                    <p>
                                    We provide virtual training, using webinars, digital conferencing, lectures and new technology to provide learning access to everyone irrespective of their location.

                                    </p>
                                </div>
                            </div>
                        </div>

                        <div className="col-md-3">
                            <div className="qtBootCampContent">
                            <div className="qtBootIcon">
                                <i className="fa fa-id-card" aria-hidden="true"></i>
                                </div>
                                <div className="qtSecHead">
                                    <h2>Training by our Industrial Expert Trainers:</h2>
                                </div>
                                <div className="qtBootContent">
                                    <p>
                                    Accelerate your career growth by getting trained by our Industrial Expert Trainers, with extensive project based learning.
                                    </p>
                                </div>
                            </div>
                        </div>

                        <div className="col-md-3">
                            <div className="qtBootCampContent">
                            <div className="qtBootIcon">
                                <i className="fa fa-id-card" aria-hidden="true"></i>
                                </div>
                                <div className="qtSecHead">
                                    <h2>Why Choose Cadbench?</h2>
                                </div>
                                <div className="qtBootContent">
                                    <p>
                                    Cadbench offers a live interactive training, with industry related projects by our experts and provide practical learning platform
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                </div>
            </div>
        </div>
    )
}
export default BootCampComponent;