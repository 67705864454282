import courseimg from '../../images/course.gif'
import { useState,useEffect } from 'react';
import parse from 'html-react-parser';
import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'
const ProjectComponent = (props) => {
    const[project,setProject] = useState([]);

    const getAllData = () => {
        let URL = window.API_URL+"course/getCourseDetails/"+props.info.id;
        fetch(URL, {
          
          method: "get",
          headers: {
			'TXNTYPE': 'CERTIFICATIONPROJECT',
		},
        })
        .then(response => response.json()
        )
        .then(data => 
          {
            
            setProject(data)
        }
        )
        .catch(err => console.log(err))
      }

      const setData = () =>{
        if(project.length > 0)
        {
            return(
                project.map((course,index) => (

                    <div key={index}>
                        <div className='qtTitle textAlignLeft'>
                <strong>{course.title}</strong>
                <br/>
                </div>
                <div className='qtProjectData textAlignLeft'>
                    {parse(course.description)}
                </div>
                        </div>  

             ))
            )
        }
        else
        {
            return(
                <div>
                    <div className='text_align_left'>
                    <Skeleton variant="text" width={150}/>
                    </div>
                    <Skeleton variant="text" count={5}/>
                    <div className='clearfix'></div>
                    <Skeleton variant="text" count={4}/>
                </div>
            )
        }
      }
    
      useEffect(() => {
        getAllData()
      }, [])
    return(
        <>
            <div className="qtProject">
                <div className="container">
                    <div className="qtTitle textAlignLeft">
                        <h2>{props.info.course_tile} Certification Projects</h2>
                    </div>
                    <div className='row'>
                        <div className='col-md-8'>

                       
                            <div className='qtProjectDetails'>
                                {
                                    setData()
                                }
                            </div>
                        
                        </div>
                        <div className='col-md-4'>
                        <div className='qtCourseImg'>
                                    <img src={courseimg} alt="Cadbench"/>
                                </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
export default ProjectComponent