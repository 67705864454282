import logo from '../../logo.png'
import first from '../../images/placed/1.png'
import second from '../../images/placed/2.png'
import third from '../../images/placed/3.png'
import fourth from '../../images/placed/4.png'
import fifth from '../../images/placed/5.png'
import sixth from '../../images/placed/6.png'
import seventh from '../../images/placed/7.png'
import eighth from '../../images/placed/8.png'
const AlumniComponent = () => {
    return(
        <div>
                <div className="container">
                <div className="qtpgHeading">
                            <h2>Our Alumni work at <span>Best Companies in the World</span></h2>
                </div>
                <div className="qtAlumni">
                        <div className="row">
                            <div className='col-md-2'>
                                <img src={first} alt="Qtree Tech"/>
                            </div>

                            <div className='col-md-2'>
                                <img src={second} alt="Qtree Tech"/>
                            </div>

                            <div className='col-md-2'>
                                <img src={third} alt="Qtree Tech"/>
                            </div>

                            <div className='col-md-2'>
                                <img src={fourth} alt="Qtree Tech"/>
                            </div>

                            <div className='col-md-2'>
                                <img src={fifth} alt="Qtree Tech"/>
                            </div>

                            <div className='col-md-2'>
                                <img src={sixth} alt="Qtree Tech"/>
                            </div>
                        </div>
                        <div className='clearfix'></div>
                        <div className="row">
                            <div className='col-md-2'>
                                <img src={seventh} alt="Qtree Tech"/>
                            </div>

                            <div className='col-md-2'>
                                <img src={eighth} alt="Qtree Tech"/>
                            </div>

                           
                        </div>
                        <div className='clearfix'></div>
                        
                </div>
                </div>
        </div>
    );
}

export default AlumniComponent;