import { useState } from "react"

const ContactComponent = () => {
    const[isWidget,setWidget] = useState(false);
    const setShowModal = ()=>{
        setWidget(true);
    }

    const setHideModal = () => {
        setWidget(false);
    }
    
    return(
        <>
            <div className="qtContactWidget" onClick={setShowModal}>
                <div className="qtContactWidgets">
                    <span className="qtContactIcon"><svg xmlns="http://www.w3.org/2000/svg" width="16.523" height="16.491" viewBox="0 0 16.523 16.491"><path data-name="Path 24546" d="M53.8,12.05l-2.3-2.3a1.569,1.569,0,0,0-2.2,0,2.092,2.092,0,0,0-.4.6,1.566,1.566,0,0,1-1.8,1,7.3,7.3,0,0,1-4.3-4.3,1.431,1.431,0,0,1,1-1.8,1.584,1.584,0,0,0,1-1.9,2.092,2.092,0,0,0-.4-.6L42.1.45a1.569,1.569,0,0,0-2.2,0l-1.6,1.6c-1.6,1.6.2,6,4,9.8,3.9,3.9,8.2,5.7,9.8,4l1.6-1.6a1.485,1.485,0,0,0,.1-2.2" transform="translate(-37.72)"></path></svg></span>
                    Contact Us
                </div>
            </div>
           {
            isWidget && 
            <div className="qtContactModal">
                    <div className="qtContactModals">
                        <div className="qtContactClose" onClick={setHideModal}>
                            Close
                        </div>
                        <div className="qtContactTitle">
                            Call us for any query
                            <div className="qtContactTitles">
                               
                                <div className="qtContacts">
                                <span><i class="fa fa-phone" aria-hidden="true"></i></span>Call +91 9500024134<br/>
                                <div className="qtCtAvailable">Available 24x7 for your queries</div>
                                </div>
                                
                                
                            </div>
                        </div>
                    </div>
            </div>
           }
            


        </>
    )
}
export default ContactComponent