import { useState,useEffect } from "react";
import { LazyLoadImage } from 'react-lazy-load-image-component';
import ImagePlaceholder from '../../logos.png'
import parse from 'html-react-parser';
import 'react-lazy-load-image-component/src/effects/blur.css';
import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'
const ToolsComponent = (props) => {
    const[skillCovered,setSkillCovered] = useState([]);
    const[skillTools,setSkillTools] = useState([]);
    const getAllSkillsCovered = () => {
        let URL = window.API_URL+"course/getCourseDetails/"+props.info.id;
        fetch(URL, {
          
          method: "get",
          headers: {
			'TXNTYPE': 'SKILLSCOVERED',
		},
        })
        .then(response => {
            return response.json()
        }
        )
        .then(data => 
          {
            
            setSkillCovered(data)
        }
        )
        .catch(err => console.log(err))
      }

      const setDataCovered = () =>{
            if(skillCovered.length > 0)
            {
                return(
                        <ul>
                            {skillCovered.map((course,index) => (
                                    <li key={index}>{parse(course.description)}</li>
                             ))}
                            </ul>
                    )
            }
            else
            {
                return (
                        <ul>
                
                {Array.from(Array(4), (e, i) => {
                            return(
                                
                                <li key={i}><Skeleton variant="text" width={302} count={1}/></li>
                            )
                        })
                        }
                </ul>
                    )
            }
      }

      const setDataSkillTools = () =>{
        if(skillTools.length > 0)
        {
            return(
                    <ul>
                        {skillTools.map((course,index) => (
                                <li key={index}>{parse(course.title)}</li>
                         ))}
                        </ul>
                )
        }
        else
        {
            return (
                    <ul>
            
            {Array.from(Array(4), (e, i) => {
                        return(
                            
                            <li key={i}><Skeleton variant="text" width={302} count={1}/></li>
                        )
                    })
                    }
            </ul>
                )
        }
  }

      const getAllSkillsTools = () => {
        let URL = window.API_URL+"course/getCourseDetails/"+props.info.id;
        fetch(URL, {
          
          method: "get",
          headers: {
			'TXNTYPE': 'SKILLTOOLS',
		},
        })
        .then(response => response.json()
        )
        .then(data => 
          {
           
            setSkillTools(data)
        }
        )
        .catch(err => console.log(err))
      }

    
     

  useEffect(() => {
    getAllSkillsCovered()
    getAllSkillsTools()
  }, [])
    return(
        <>
            <div className="qtTools">
                <div className="container">
                    <div className="qtTitle textAlignLeft">
                    <h2>Skills Covered through {props.info.course_tile} in Coimbatore</h2>
                    
                    </div>
                    <div className="qtToolsContent">

                    {
                        setDataCovered()
                    }

                    </div>
                    <div className="clearfix"></div>
                    <div className="qtTitle textAlignLeft">
                    <h2>{props.info.course_tile} Tools Covered</h2>
                    </div>

                    <div className="qtToolsContent">

                    {
                        setDataSkillTools()
                    }

                    </div>

                </div>
            </div>
        </>
    )
}
export default ToolsComponent;