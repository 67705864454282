import aboutus from '../../../images/aboutus.jpg';
const WhoAreWeComponent = () => {
    return(
        <>
            <div className="qtreeAboutUs">
                <div className="container">
                <div className="qtMainHeads textAlignLeft">
                    <div className="container">
                    <h4>Who Are We</h4>
                    <div className='qtHrSmallBorder'>
                    <div className='qtHrLineBorder'></div>
                    </div>
                    </div>
                </div>
                <div className='clearfix'></div>
                    <div className="row">
                    <div className="col-md-9">
                            <div className='qtAboutMainContent'>
                                <p>
                                Cadbench is a division of CubikCadd, which was founded in the year 2019. Since its commencement, it has developed colossally and presently flaunts two instructional hubs in Coimbatore. Getting from the tremendous experience of study hall preparation, Cadbench appeared in 2023 to take care of the adaptable advancing requirements of working experts.</p>
                                <p>Cadbench offers many web-based seminars on Mechanical Autocad, Sketchup Preparing, PDMS& E3D, Primavera, Revit Design, and Electrical Autocad. You can dominate any product you wish to, right from the solace of your home. Cadbench can assist you with arriving at the pinnacle of your profession by furnishing you with the most recent mechanical abilities which will transform you into a resource for any association that you decide to work with.</p>
                                <p>Every one of the web-based courses is led by confirmed specialists who have immense subject information and huge experience. With learning made so straightforward and open, it is simpler to accomplish your vocation objectives than ever previously. Hoping to improve your range of abilities yet need more time available? Cadbench is your answer for adaptable learning! Feel free to select now to move forward in the profession.</p>
                            </div>
                        </div>
                        <div className="col-md-3">

                            <div className="qtAboutImg">
                                    <img src={aboutus} width="100%"/>
                            </div>

                        </div>
                       
                    </div>
                </div>
            </div>
        </>
    );
}
export default WhoAreWeComponent;