import dsimg from '../../images/data_science.png';
import first from '../../images/domain/1.png'
import second from '../../images/domain/2.png'
import third from '../../images/domain/3.png'
import fourth from '../../images/domain/4.png'
import fifth from '../../images/domain/5.png'
import { Link } from 'react-router-dom';
const ExploreComponent = () => {
    return(
        <div>
            <div className="qtExplore">
            <div className="qtMainHead">
                    <div className="container">
                    <h4>Explore Cadbench</h4>
                    </div>
            </div>
            <div className="qtMainContents">
                <div className="container">
                    <div className="row">
                        <div className="col-md-3">
                        <Link to="#" className='hover-light-teal text-decoration-none text-color-black'>
                            <div className="qtExpContent">
                                {/* <div className='row'> */}
                                <div className=''>
                                   <img src={first} width="50%" alt='QTree Tech'/>
                                </div>
                                <div className=''>
                                    <div className="qtExpContents">Autocad</div>
                                </div>
                                {/* </div> */}
                            </div>
                            </Link>
                        </div>

                        <div className="col-md-3">
                        <Link to="#" className='hover-light-teal text-decoration-none text-color-black'>
                            <div className="qtExpContent">
                                
                                <div className=''>
                                    <img src={second} width="50%" alt='QTree Tech'/>
                                </div>
                                <div className=''>
                                    <div className="qtExpContents">Solidworks</div>
                                    
                                </div>
                                
                            </div>
                            </Link>
                        </div>

                        <div className="col-md-3">
                        <Link to="#" className='hover-light-teal text-decoration-none text-color-black'>
                            <div className="qtExpContent">
                               
                                <div className=''>
                                    <img src={fifth} width="50%" alt='QTree Tech'/>
                                </div>
                                <div className=''>
                                    <div className="qtExpContents">Creo</div>
                                    
                                </div>
                                
                            </div>
                            </Link>
                        </div>

                        <div className="col-md-3">
                        <Link to="#" className='hover-light-teal text-decoration-none text-color-black'>
                            <div className="qtExpContent">
                                
                                <div className=''>
                                    <img src={fourth} width="50%" alt='QTree Tech'/>
                                </div>
                                <div className=''>
                                    <div className="qtExpContents">Siemens NX</div>
                                    
                                </div>
                               
                            </div>
                            </Link>
                        </div>
                    </div>

                    {/* <div className='clearfix'></div>

                    <div className="row">
                        <div className="col-md-3">
                            <div className="qtExpContent">
                               
                                <div className=''>
                                    <img src={fifth} width="50%" alt='QTree Tech'/>
                                </div>
                                <div className=''>
                                    <div className="qtExpContents">Revit</div>
                                    
                                </div>
                                
                            </div>
                        </div>

                    </div> */}
                </div>
            </div>
            </div>
        </div>
    )
}

export default ExploreComponent