import HomeComponent from "./components/home";
import CourseComponent from "./components/courses";
import MyCourseComponent from "./components/account/courses";
import ContactComponent from "./components/contact";
import AboutComponent from "./components/about";
import TestPayment from "./components/payment/TestPayment";
import {BrowserRouter as Router, Routes, Route} from "react-router-dom"
import React, { useEffect } from "react";
import { checkAutoLogin } from "./components/actions";
import { useDispatch,useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import AllCourses from "./components/courses/all";
import ProfileComponent from "./components/account";
import CartComponent from "./components/account/cart";
import WishListComponent from "./components/account/wishlist";
import GoogleCallback from "./components/google/GoogleCallback";
import ShortLinkComponent from "./components/ShortLink";
import TermsComponent from "./components/TermsAndPolicy/TermsComponent";
import PrivacyComponent from "./components/TermsAndPolicy/PrivacyPolicy";
import CourseDetails from "./components/account/coursedetails";
import CancelRefund from "./components/TermsAndPolicy/CancelRefund";
import ShippingDelivery from "./components/TermsAndPolicy/ShippingDelivery";
import EmailSuccess from "./components/verification/success";
import EmailFailure from "./components/verification/failure";

const App = () => {
  
  const dispatch = useDispatch();
  const { usersData, isLogin,cartCount,currency,currencyValue } = useSelector((state) => state.LoginLogout);
  //const loginState = useSelector((state)=>state.LoginLogout);
  //dispatch(usersData,isLogin,cartCount,"INR","1");
  useEffect(() => {
    checkAutoLogin(dispatch,usersData);
  }, [])

  return (
    <div className="App">
      <Router>
      {/* <Router basename={'/sandbox'}> */}
          
          {/* <HeaderComponent/> */}
          <Routes>
              <Route path="/" element ={<HomeComponent/>}/>

              <Route path="/courses" element={<AllCourses/>}/>
              <Route path="/courses/:courseid" element={<CourseComponent/>}/>
              <Route path="/courses/search/:courseid" element={<AllCourses/>}/>
              
              <Route path="/about" element={<AboutComponent/>}/>
              <Route path="/contact" element={<ContactComponent/>}/>
              <Route path="/payment" element={<TestPayment/>}/>
              <Route path="/myprofile" element = {<ProfileComponent/>}/>
              <Route path ="/mycourses" element = {<MyCourseComponent/>}/>
              <Route path="/mycourses/info/:slug" element={<CourseDetails/>}/>
              <Route path="/mycart" element={<CartComponent/>}/>
              <Route path="/mywishlist" element={<WishListComponent/>}/>
              <Route path=":code" element={<ShortLinkComponent/>}/>
              <Route path="/auth/google" element={<GoogleCallback />}></Route>
              <Route path="/termsconditions" element={<TermsComponent/>}></Route>
              <Route path="/privacypolicy" element={<PrivacyComponent/>}></Route>
              <Route path="/cancelrefund" element={<CancelRefund/>}></Route>
              <Route path="/shippingdelivery" element={<ShippingDelivery/>}></Route>
              <Route path="/email_success" element={<EmailSuccess/>}></Route>
              <Route path="/email_failure" element={<EmailFailure/>}></Route>


          </Routes>
      </Router>
       
    </div>
  );
}
export default App;
