import {useState, useEffect} from 'react';
import {useLocation} from "react-router-dom";
import LoadingComponent from '../loading';
import { useDispatch,useSelector } from 'react-redux';
import { login } from '../actions';

function GoogleCallback() {

    const { usersData, isLogin,cartCount,currency,currencyValue } = useSelector((state) => state.LoginLogout);
    const dispatch = useDispatch();
    const [loading, setLoading] = useState(true);
    const [isAlreadyCalled,setAlreadyCalled] = useState(false);
    const location = useLocation();

    useEffect(() => {

        if(!isAlreadyCalled)
        {
            setAlreadyCalled(true);
        fetch(window.API_URL+`auth/callback${location.search}`, {
            headers : {
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            }
        })
            .then((response) => {
                return response.json();
            })
            .then((res) => {
                setLoading(false);

                if(res['message'].includes("error"))
                {
                    alert("Something Went Wrong!");
                    window.location.replace(window.SITE_URL);
                 }
                 else
                     getCartCount(res['data']);

            });
        }
    }, []);

    const getCartCount = (loginResponse) => {
        fetch(window.API_URL+`getCartCount/`+loginResponse.id).then((response) => {
          return response.json()
        }).then((res) => {
          setLoading(false);
          dispatch(login(loginResponse,res.count,currency,currencyValue));
          window.location.replace(window.SITE_URL)
        })
    }

    return(
        <LoadingComponent isShow = {loading}/>
    )

}

export default GoogleCallback;