import { useLocation } from "react-router-dom";
import { useSelector,useDispatch } from 'react-redux';
import { useState,useEffect } from "react";
import docs from '../../images/file/docx.png'
import pdf from '../../images/file/pdf.png'
import jpg from '../../images/file/jpg.png'
import png from '../../images/file/png.png'
import download from '../../images/file/download.png'
import HeaderComponent from "../header";
import { Table } from "react-bootstrap";
import LoadingComponent from "../loading";
import { toast,ToastContainer} from 'react-toastify';

const CourseDetails = (props) =>{
    const location = useLocation();
    const [menu,setMenu] = useState("");
    const { usersData, isLogin} = useSelector((state) => state.LoginLogout);
    const[isLoading,setLoading] = useState(false);
    const [batch,setBatch] = useState([]);
    const [curBatch,setCurBatch] = useState([]);
    const [materials,setMaterials] = useState([]);
    let courseId = 0;
    
    const[stateObject,setStateObject] = useState({
        fetched:false,
        id:0
      })

    if(isLogin !== true)
    {
        window.location.replace(window.SITE_URL);
    }

    const getCurrentBatch = () => {
        setLoading(true);
        setMenu("batch");
        var myHeaders = new Headers();
            myHeaders.append("Authorization", 'Bearer '+usersData['token']);
          
            fetch(window.API_URL+"getMyBatch/"+stateObject['id'], {
                method: 'GET',
                headers: myHeaders,
            })
            .then(response => response.json()
            )
            .then(data => 
            {
               
                let empty = Object.keys(data).length === 0
                
                if(!empty)
                {
                    setCurBatch(data[0]);
                    //renderBatchDetails(data[0].batch_id);
                }
                else{
                    renderBatchDetails("");
                }
                
                setLoading(false)
                
            }
            )
            .catch(err => {
                setLoading(false)
            })
    }

    const selectBatch = (index) => {
        setLoading(true)
        var myHeaders = new Headers();
            myHeaders.append("Authorization", 'Bearer '+usersData['token']);
            var formdata = new FormData();
            formdata.append("course_id", stateObject['id']);
            formdata.append("batch_id", index);
            formdata.append("batch_date",batch[index]['batch_date']);
            formdata.append("batch_days",batch[index]['batch_days']);
            formdata.append("batch",batch[index]['batch']);
            formdata.append("time",batch[index]['time']);
            fetch(window.API_URL+"selectBatch", {
                method: 'POST',
                headers: myHeaders,
                body: formdata,
            })
            .then(response => response.json()
            )
            .then(data => 
            {
                if(data.success === 1)
                {
                    //setCurBatch(data.data);
                    //renderBatchDetails(data.data.id);
                    toast.success("Batch Selected", {
                        position: toast.POSITION.TOP_RIGHT
                      });
                      setBatch([]);
                      getCurrentBatch();  
                }
                else{
                    toast.error("Please Try Again!", {
                        position: toast.POSITION.TOP_RIGHT
                      });
                }
                setLoading(false)
                
            }
            )
            .catch(err => {
                setLoading(false)
            })
    }

    const renderFileType = (fileType) =>{
        let type = fileType.split('.');
        type = type[1]
        if(type === "doc" || type === "docx")
        {
                return(
                    <img src={docs} alt="cadbench"/>
                )
        }
        else if(type === "pdf")
        {
            return(
                <img src={pdf} alt="cadbench"/>
            )
        }
        else if(type === "jpg" || type === "jpeg")
        {
            return(
                <img src={jpg} alt="cadbench"/>
            )
        }
        else if(type === "png")
        {
            return(
                <img src={png} alt="cadbench"/>
            )
        }
        else
        {
            return ""
        }
    }

    const batchStatus = () => {
        if(curBatch.is_approved === 0)
        {
            return "Request Pending";
        }
        else
        {
            return "Approved";
        }
    }

    const getMaterials = () =>{
        setLoading(true)
        var myHeaders = new Headers();
        myHeaders.append("Authorization", 'Bearer '+usersData['token']);
        fetch(window.API_URL+"getMaterials/"+stateObject['id'], {
            method: "get",
            headers: myHeaders,
            })
            .then(response => response.json()
            )
            .then(data => 
                {
                    if(data.success === 1)
                    {
                        
                        setMaterials(data.data);
                        setMenu("material");
                    }
                    setLoading(false)
                    
                }
                )
                .catch(err => {
                    setLoading(false)
                })
    }

    const renderBatchDetails = () => {
            setLoading(true)
            var myHeaders = new Headers();
            myHeaders.append("Authorization", 'Bearer '+usersData['token']);
            //Current Batch ID
            // if(id !== "")
            //     myHeaders.append("CURRENTID",id);
            fetch(window.API_URL+"getBatchCourseDetails/"+stateObject['id'], {
            method: "get",
            headers: myHeaders,
            })
            .then(response => response.json()
            )
            .then(data => 
            {
                
                if(data.success === 1)
                {
                     if(curBatch.length !== 0)
                     {
                     setBatch(data.data.filter((v,i) => v.batch_date !== changeDate()));
                    }
                    else
                    {
                        setBatch(data.data);
                    }
                    
                }
                setLoading(false)
                
            }
            )
            .catch(err => {
                setLoading(false)
            })
    }

    const changeDate = () =>{
        let batchDates = curBatch.batch_date.split("-");
        let changeBatchDate = batchDates[2]+"-"+batchDates[1]+"-"+batchDates[0];
        return changeBatchDate;
    }

    const renderDetails = () => {
        if(menu === "batch")
        {
            return(
                <>
                     <div className="profileRightTop">
                            Batch Details
                        </div>
                                <div className="batchContent">

                                {
                                    Object.keys(curBatch).length !== 0 &&
                                    (
                                        <div>
                                        <div className="profileSubHeading">
                                        Current Batch
                                    </div>
    
                                    <div className="currentBatchData">
                                        <Table striped bordered hover responsive="sm">
                                            <tbody>
                                                <tr>
                                                    <td>Batch Date</td>
                                                    <td>{changeDate()}</td>
                                                </tr>
                                                <tr>
                                                    <td>Batch Time</td>
                                                    <td>{curBatch.time}</td>
                                                </tr>
                                                <tr>
                                                    <td>Batch Days</td>
                                                    <td>{curBatch.batch_days}</td>
                                                </tr>
                                                <tr>
                                                    <td>Batch</td>
                                                    <td>{curBatch.batch}</td>
                                                </tr>
                                                <tr>
                                                    <td>Status</td>
                                                    <td>{batchStatus()}</td>
                                                </tr>
                                                </tbody>
                                        </Table>
                                    </div>
                                    <div className="float-right qtCourseButtons">
                                <button className="submit" onClick={()=>renderBatchDetails()}>Change Batch</button>
                                </div> 
                                    </div>
                                    )
                                }
                               
                               
                                {batch.length > 0 && (
                                    <div>
                                    <div className="profileSubHeading">
                                    Available Batches
                                    </div>

                                    <Table striped bordered hover responsive="sm">
                                        <thead>
                                            <tr>
                                                <th>Batch Date</th>
                                                <th>Batch Time</th>
                                                <th>Batch Days</th>
                                                <th>Batch</th>
                                                <th></th>
                                            </tr>
                                            </thead>
                                           
                                            <tbody>
                                            {batch.map((course,index) => (
                                                <tr key={index}>
                                                    
                                                    <td>{course.batch_date}</td>
                                                    <td>{course.time}</td>
                                                    <td>{course.batch_days}</td>
                                                    <td>{course.batch}</td>
                                                    <td><span className="batchLink" onClick={()=>{selectBatch(index)}}>Select Batch</span></td>
                                                    
                                                </tr>
                                                
                                            ))}
                                            </tbody>
                                        
                                    </Table>
                                    </div>
                                )};
                                </div>
                        
                </>
            )
        }
        else if(menu === "material")
        {
            return (
                <>
                <div className="profileRightTop">
                            Batch Details
                        </div>
                <div className="viewMaterial">

                        {materials.length > 0 && (
                                            <ul>
                                            {materials.map((course,index) => (
                                                <li key={index}>
                                                <div className="row vMaterial">
                                                    <div className="col-md-10">
                                                        <div className="text_align_left matDescription">{course.title}</div>
                                                    </div>
                                                    <div className="col-md-1">
                                                        {
                                                            renderFileType(course.image)
                                                        }
                                                    </div>
                                                    <div className="col-md-1">
                                                    <a
                                                    href={window.IMG_URL+course.image}
                                                    download={course.title}
                                                    target="_blank"
                                                    rel="noreferrer"
                                                >
                                                        <img className="download" src={download} alt={course.title}/>
                                               </a>
                                               </div>
                                                </div>
                                            </li>
                                                
                                            ))}
                                            </ul>
                                        )}
                </div>
                </>
            )
        }
        else
        {

        }
    }

    useEffect(()=>{
    
        if(location.state  && !stateObject.fetched)
        {
             setStateObject({
              ...stateObject,
              fetched:true,
              id:location.state.id
             })
        }
    },[])

    useEffect(()=>{
   
    
        if(!location.state  && !stateObject.fetched)
        {
            const pathname = window.location.pathname;
                const slug = pathname.substring(pathname.lastIndexOf('/') + 1);
                let URL = window.API_URL+"course/"+slug;
            // const getData  = async()=>{
            //     console.log("Loaded second");
            //     const pathname = window.location.pathname;
            //     const slug = pathname.substring(pathname.lastIndexOf('/') + 1);
            //     let URL = window.API_URL+"course/"+slug;
            //     try {
            //     const fetchResponse = await fetch(URL);
            //     const data = await fetchResponse.json(); 
            //     console.log("My Data =>"+data[0].id);
               
            //     setStateObject({
            //       ...stateObject,
            //       fetched:true,
            //       id:data[0].id,                
            //      });

                 
            //       } catch (e) {
                      
            //       }  
            //       finally{
            //         getCurrentBatch();
            //       }
            // }
            // getData();

          

              fetch(URL).then((response) => {
              return response.json()
              }).then((res) => 
              {
                courseId = res[0].id;
                 setStateObject({
                  ...stateObject,
                  fetched:true,
                  id:res[0].id,
                 })
              })
        }
    
      },[stateObject,location.state])

    

    return(
        <div>
            <LoadingComponent isShow = {isLoading}/>
            <HeaderComponent isHome="false"/>
            <div className="container">
                <div className="profileContent">
                <div className="row">
                    <div className="col-md-3 profileLeft">
                        
                        <div className="profileHomeTop">
                            {usersData.name}
                        </div>
                        <div className="profileMenu">
                            <ul>
                                <li onClick={()=>getCurrentBatch()}>Batch Details</li>
                                <li onClick={()=>getMaterials()}>Material</li>
                                <li onClick={()=>setMenu("classroom")}>Class Room Link</li>
                            </ul>
                        </div>
                        
                    </div>

                    <div className="col-md-9 profileRight">
                       {
                            renderDetails()
                       }
                    </div>

                </div>
                </div>
            </div>
        </div>
    )
}
export default CourseDetails;