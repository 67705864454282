export const login = (json,cart,cur,value) =>{
  
    return{
        
        type:"LOGIN",
        usersData: json,
        cartCount:cart,
        currency:cur,
        currencyValue:value
        
    }
}

export const cur = (json,count,login,cur,value) => {

    return{
        type:"CURRENCY",
        usersData:json,
        cartCount:count,
        isLogin:login,
        currency:cur,
        currencyValue:value
    }
}

export const logout = () => {
    localStorage.removeItem("DATA");
    return{
        type:"LOGOUT",
        usersData: [],
        cartCount:0,
    }
}

export const cartItem = (json,count,cur,value) => {

    return{
        type:"CART",
        usersData: json,
        cartCount:count,
        currency:cur,
        currencyValue:value,
    }
}

export const saveLocalStorage = (data) => {
    
    data.expireDate = new Date().getTime()+data.expiresIn*1000;
    localStorage.setItem("DATA",JSON.stringify(data));
}

export const getLocalStorage = () => {
    return localStorage.getItem("DATA");
}


export function checkAutoLogin(dispatch,loginLogout)
{

    // if((loginLogout.usersData.length) === 0)
    // {
    //     dispatch(logout());
    // }
    // else
    // {
    //     dispatch(login(loginLogout.usersData,loginLogout.cartCount));
    // }

    //loginLogout.usersData);
  // alert(loginState.cartCount);
  // alert(loginState.isLogin);

   // alert(loginLogout.usersData);

    /*const tokenDetailsString = localStorage.getItem("DATA");
    let tokenDetails = '';
    if(!tokenDetailsString){
        dispatch(logout());
        return;
    }
    console.log("Auto Login Called");

    alert("Token =>"+tokenDetailsString);
    dispatch(login(tokenDetailsString,5));
    */
    
}