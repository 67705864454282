import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Form from 'react-bootstrap/Form';
import { Link } from 'react-router-dom';
import courseimg from '../../images/course.gif'
const AddCart = (props) =>{
    return(
        <Modal show={props.isshow}
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      
      <Modal.Body className='show-grid'>
        <Container>
            <Row>
                <Col xs={12} md={6} className='qtModalAppLeft'>
                    <div>
                        <img src={courseimg} alt="Cadbench" width="100%"/>
                    </div>
                </Col>
                <Col xs={12} md={6}>
                        <div className='qtModalAppRight'>
                       
                            <div className='cadAddedCart'>
                                Course is Added to Cart!
                            </div>
                            <div className='cadBtn'>
                            <Link className="cadCart" to="/mycart">Go To Cart</Link>
                                {/* <button className="cadCart">Go To Cart</button> */}
                                <button className="cadCartContinue" onClick={()=>props.isclose()}>Continue</button>
                            </div>  
                        </div>
                </Col>
            </Row>
        </Container>
      </Modal.Body>
    </Modal>
    )
}
export default AddCart