import AboutBannerComponent from "./banner";
import FooterComponent from "../footer";
import HeaderComponent from "../header";
import AboutUsComponent from "./aboutus";
import WhoAreWeComponent from "./whoarewe";
import WhatWeDoComponent from "./whatwedo";
import MasterComponent from "../master";
const AboutComponent = () =>{
    return(
        <div>
            <HeaderComponent isHome="false"/>
            {/* <AboutBannerComponent/> */}
            <AboutUsComponent/>
            <div className='clearfix'></div>
            <WhoAreWeComponent/>
            <div className='clearfix'></div>
            <WhatWeDoComponent/>
            <div className='clearfix'></div>
            <MasterComponent/>
            <div className='clearfix'></div>
            <FooterComponent/>
        </div>
    )
}
export default AboutComponent;