import HeaderComponent from "../header";
import ProfileHomeComponent from "./profilehome";
import { useSelector,useDispatch } from 'react-redux';
const ProfileComponent = () =>{
    const { usersData, isLogin } = useSelector((state) => state.LoginLogout);
    if(isLogin !== true)
    {
        window.location.replace(window.SITE_URL);
    }
    else{
    return(
        <div>
                <HeaderComponent isHome="false"/>
                <ProfileHomeComponent/>
        </div>
    )
}
}
export default ProfileComponent;