import { Link } from "react-router-dom";
import { useState,useEffect } from "react";
import { useSelector,useDispatch } from 'react-redux';
import LoadingComponent from "../loading";
import { cur } from "../actions";
import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'
const ProgramComponent = (props) => {
  const nf = new Intl.NumberFormat();
  const users = useSelector((state)=>state.LoginLogout);
  const dispatch = useDispatch();
  const[isLoading,setLoading] = useState(false);
  const[courses,setCourses] = useState([]);
  const[limit,setLimit] = useState(2);


  const convertCurrency = (amount) => {
    
    if(users.currency === "USD")
    {
        let fee = Math.round(amount*users.currencyValue);
        return(
          nf.format(fee)
        )
    }
    else{
     
      return amount
    }
  }

  const changeCurrency = (source,destination) => {
    setLoading(true);
    let url = window.API_URL+"convertInrToUsd";
    let datas = new FormData();
    datas.append("source",source);
    datas.append("destination",destination);
    fetch(url,{
      method:"post",
      body:datas,
    })
    .then(response=>response.json())
    .then(data=>{
      setLoading(false);
      if(JSON.stringify(data).includes("errors"))
      {
          console.log("Something went wrong");
      }
      else
      {
        if(data.success === 1)
        {
          
          // dispatch(cur(users.usersData,users.cartCount,users.isLogin,destination,data.value));
          dispatch(cur(users.usersData,users.cartCount,users.isLogin,destination,data.value.data.USD));
        }
      }
    })
  }

  const getAllCourses = () => {
    //setLoading(true);
    let url = "";
    if(props.searchContent !== "")
      url = window.API_URL+"getCourses/"+props.searchContent;
    else
      url = window.API_URL+"getCourses";
    if(props.source === "home")
    {
      url = window.API_URL+"getCourseLimit/3";
    }

    fetch(url, {
      
      method: "get",
    })
    .then(response => {
     return response.json()
    }
    )
    .then(data => 
      {
        
        if(props.source === "home")
          setLimit(3);
        else
          setLimit(data.length);
        setCourses(data)
       
    }
    )
    .catch(err => {
      console.log(err)
    })
    
  }

  const setData = () => {
    
      if(courses.length > 0)
      {
         return(
          <>
              <div className="row">
                  {courses.slice(0, limit).map((course,index) => (
                    <div className="col-md-4" key={course.id}>

                    <div className="qtPrograms">
                          <div className="programsHead programsHeadCard1">
                          <div className="programsheadText wow fadeInDown animate animated">
                              <span>{course.course_tile}</span>
                          </div>
                          <div className="programsHeadImg">
                              <img className="programs__head-img" alt="QTree Tech" data-src="https://assets.scaler.com/assets/scaler/svg/code_settings-2fc7b0fb45a5f45f32ae987cae5ec3facd518adc57ab5c581c054e4156aeff18.svg.gz" src="https://assets.scaler.com/assets/scaler/svg/code_settings-2fc7b0fb45a5f45f32ae987cae5ec3facd518adc57ab5c581c054e4156aeff18.svg.gz"/>
                              </div>
                          </div>
                          <div className="programs__body">
                          <div className="programs__pointers">
                              <div className="programs__pointer">
                                <div className="programs__pointer-head">
                                  Career Outcome
                                </div>
                                <div className="programs__pointer-desc">
                                  {course.outcome}
                                </div>
                              </div>
                              <div className="programs__pointer">
                                <div className="programs__pointer-head">
                                  What You Will Learn
                                </div>
                                <div className="programs__pointer-desc">
                                {course.learn}
                                </div>
                              </div>
                              <div className="programsFee">
                                  <span><i className={(users.currency === "INR")?"fa fa-rupee":"fa fa-usd"}></i></span>&nbsp;
                                  {
                                    convertCurrency(course.fee)
                                  }
                              </div>
                              <div className="programsLink">
                              <Link to={{pathname:"/courses/"+course.slug, state: {course}}}>
                  Learn More
                </Link>
                              
                              </div>
                          </div>
                          </div>
                          
                          </div>
                </div>
                  ))}
                  </div>

          </>
         )
      }
      else
      {
          return(
            <>
              
              <div className="row">

              {Array.from(Array(3), (e, i) => {
                return(
                  <>
                    <div className="col-md-4" key={i}>

                          <div className="qtPrograms">
                                <div className="programsHead programsHeadCardSk1">
                                <div className="programsheadText wow fadeInDown animate animated">
                                    <span> <Skeleton  count={1} width={250}/></span>
                                </div>
                                <div className="programsHeadImg">
                                <Skeleton variant="circle" width={80} height={80} />
                                    {/* <img className="programs__head-img" alt="QTree Tech" data-src="https://assets.scaler.com/assets/scaler/svg/code_settings-2fc7b0fb45a5f45f32ae987cae5ec3facd518adc57ab5c581c054e4156aeff18.svg.gz" src="https://assets.scaler.com/assets/scaler/svg/code_settings-2fc7b0fb45a5f45f32ae987cae5ec3facd518adc57ab5c581c054e4156aeff18.svg.gz"/> */}
                                    </div>
                                </div>
                                <div className="programs__body">

                                <div className="programs__pointers">
                                <Skeleton  count={10}/>
                                <Skeleton  count={1} width={150}/>
                                </div>
                                </div>

                                </div>
                          </div>
                  </>
                )
              })
              }
                 
                    
                  </div>
            </>
          )
      }
  }

  useEffect(() => {
    //setInitialCurrency()
    getAllCourses()
    
  }, [])

  

    return(
        <div>
            
              <LoadingComponent isShow = {isLoading}/>
                <div className="container">
                    <div className="qtpgRow">
                        <div className="qtpgHeading">
                            <h2>Programs <span>We Offer</span></h2>
                            <div>
                              <span className="currencyConversion" onClick={()=>changeCurrency(
                                (users.currency === "INR")?"INR":"USD",
                                (users.currency === "INR")?"USD":"INR"
                              )}>
                                {(users.currency === "INR")?"Change To USD":"Change To INR"}
                              </span>
                            </div>
                        </div>
                        <div className="qtBody">
                        <div className="">
                                  {
                                    setData()
                                  }
                        </div>
                        </div>
                    </div>
                </div>
        </div>
    )
};
export default ProgramComponent;