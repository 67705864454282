import React, { useRef,useState,useEffect } from 'react';
import Button from 'react-bootstrap/esm/Button';
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import { Link } from 'react-router-dom';
import google from '../../images/google.png';
import { Formik,Form as FormikForm,Field,ErrorMessage } from 'formik';
import * as Yup from 'yup'
import { GoogleLogin,googleLogout } from '@react-oauth/google';
import { GoogleOAuthProvider } from '@react-oauth/google';
 import { useGoogleLogin } from '@react-oauth/google';
import jwtDecode from 'jwt-decode';
import { useSelector,useDispatch } from 'react-redux';
import { cartItem, login,logout,runLogoutTime,saveLocalStorage } from '../actions';
import { toast,ToastContainer} from 'react-toastify';
import LoadingComponent from '../loading';
import TextError from '../error/TextError';
import Swal from 'sweetalert2'
import ReCAPTCHA from "react-google-recaptcha";
/**
 * Login and Registration Dialog
 */
const clientId = "661776952066-qb1g4nnpjjt9bh6s2l43fmrfhm0ntr46.apps.googleusercontent.com";
const ModalDialog = (props) => {
  const loginState = useSelector((state)=>state.LoginLogout);
  const dispatch = useDispatch();
  const recaptchaRef = useRef(null);
    const[isLoading,setLoading] = useState(false);
    const[isLogin,setIsLogin] = useState(true);
    const [validated, setValidated] = useState(false)
    const [ user, setUser ] = useState([]);
    const [ profile, setProfile ] = useState([]);
    const[name,setName] = useState("");
    const[email,setEmail] = useState("");
    const[mobile,setMobile] = useState("");
    const[password,setPassword] = useState("");
    const[cpassword,setConPassword] = useState("");
    const [loginUrl, setLoginUrl] = useState(null);
    const formRef = useRef(null);

    const loginValues = {
      email:'',
      password:''
    }

    const phoneRegExp = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/

    const validationLoginSchema = Yup.object(
      {
          email:Yup.string().email('Invalid email format!').required('Required!'),
          password:Yup.string().required('Required!')
      });

    const initialValues = {
      name:'',
      email:'',
      mobile:'',
      password:'',
      confirmPassword:''
    }
    const passwordRules = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}$/;
    const validationSchema = Yup.object(
      {
          name: Yup.string().required('Required!'),
          email:Yup.string().email('Invalid email format!').required('Required!'),
          //mobile:Yup.number().required('Required').min(10).max(12),
          mobile: Yup.string().required('Required').matches(phoneRegExp, 'Mobile Number is not valid').min(10),
          password:Yup.string()
          .matches(passwordRules, { message: "Please create a stronger password" })
          .required('Required!'),
          confirmPassword: Yup
          .string()
          .oneOf([Yup.ref("password"), null], "Passwords must match")
          .required("Required!"),
      }
  )


    const showRegistration = () => {
        setIsLogin(false)
    }

    const showLogin= () =>{
        setIsLogin(true)
    }


    const handleLogin = (values) => {

      setLoading(true);
      const datas = new FormData();
      datas.append("email", values.email);
      datas.append("password", values.password);
      datas.append("user_type", "STUDENT");
     
      let url = window.API_URL+"login";
      fetch(url, {
        "crossDomain": true,
        method: "POST",
        body: datas,
      })
      .then(response => {
          if(response.status === 200)
              return response.json()
          else
              {
                
                setLoading(false);
              }   
      }
      )
      .then(res => 
        {
          
          setLoading(false);
          if(res['success'] === true)
          {
              getCartCount(res['data']);
              
          }
          else{
            toast.error("Invalid Credentials", {
              position: toast.POSITION.TOP_RIGHT
            });
            dispatch(logout());
          }
         
      }
      )
      .catch(err => {
        toast.error("Invalid Credentials", {
          position: toast.POSITION.TOP_RIGHT
        });
      
      }
        )
    }

    const getCartCount = (loginResponse) => {
      fetch(window.API_URL+`getCartCount/`+loginResponse['id']).then((response) => {
        return response.json()
      }).then((res) => {

        if("email_verified_at" in loginResponse)
        {

        }
        else
        {
          toast.warn("Email is not verified yet! Please verify", {
            position: toast.POSITION.TOP_RIGHT
          });
        }
        dispatch(login(loginResponse,res.count,loginState.currency,loginState.currencyValue));
      })
  }

    const handleSubmit = async(values) =>{

      setLoading(true);
      const token = await recaptchaRef.current.executeAsync(); 
      const datas = new FormData();
      datas.append("name", values.name);
      datas.append("email", values.email);
      datas.append("password", values.password);
      datas.append("c_password", values.confirmPassword);
      datas.append("user_type", "STUDENT");
      datas.append("mobile",values.mobile)
      datas.append("g-recaptcha-response",token);
      
      let url = window.API_URL+"register";
      var headers = {"access-control-allow-origin" : "*",}
      fetch(url, {
        "crossDomain": true,
        method: "POST",
        body: datas,
        mode: 'cors',
        headers: headers
      })
      .then(response => response.json()
      )
      .then(data => 
        {
          setLoading(false);
          recaptchaRef.current.reset();
          if(data['success'] === true)
          {
             values.name = ""
              values.email = ""
              values.password = ""
              values.confirmPassword = ""
              values.mobile = ""
             if(data['data']['success'] === 2)
             {
              Swal.fire({
                title: 'Dublicate User!',
                text: data['data']['message'],
                icon: 'info',
                allowOutsideClick: false,
                  })
                  return
             }
             setIsLogin(true)
              toast.success("Registration Successful", {
                position: toast.POSITION.TOP_RIGHT
              });
          }
          else{
            toast.error("Something went wrong, Please Try Again!", {
              position: toast.POSITION.TOP_RIGHT
            });
          }
      }
      )
      .catch(err => {
        setLoading(false);
        recaptchaRef.current.reset();
        toast.error("Something went wrong, Please Try Again!", {
          position: toast.POSITION.TOP_RIGHT
        });
      }
        )
    }

    useEffect(() => {
      fetch(window.API_URL+`auth`, {
          headers : {
              'Content-Type': 'application/json',
              'Accept': 'application/json'
          }
      })
          .then((response) => {
              if (response.ok) {
                  return response.json();
              }
              throw new Error('Something went wrong!');
          })
          .then((data) => setLoginUrl( data.url ))
          .catch((error) => console.error(error));
  }, []);

    if(isLogin)
    {
  return (
    <>
    <ToastContainer/>
     <div> 
      <Modal show={props.isShow} onHide={props.isClose} className='modal_outer right_modal fade mobileLoginModal'>
        <Modal.Header closeButton>
          <Modal.Title>Login<br/>Or <Link className='qtLoginNew' onClick={showRegistration}>Create your Cadbench Account</Link></Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Formik
            initialValues={loginValues}
            validationSchema={validationLoginSchema}
            onSubmit={handleLogin}>
            <FormikForm >
                <div className="form-group">
                                <label htmlFor='email'>Email&nbsp;<span className='requiredField'>*</span></label>
                                <Field type="email" className="form-control" id='email' name='email' placeholder="Email"/>
                                <ErrorMessage name='email' component={TextError}/>
                </div>
                <div className='clearfix'></div>
                <div className="form-group">
                                <label htmlFor='password'>Password&nbsp;<span className='requiredField'>*</span></label>
                                <Field type="password" className="form-control" id='password' name='password' placeholder="Password"/>
                                <ErrorMessage name='password' component={TextError}/>
                </div>
                <div className='clearfix'></div>
              <button type="submit" className="qtLoginBtn">Login</button>
              <div className='hrRule'></div>
              <div>
              {loginUrl != null && (
                  <a href={loginUrl} className="googleLogin"><img src={google} width="10%" alt='Cadbench'/>Google Sign In</a>
              )}
          </div>
            </FormikForm>
          </Formik>
        </Modal.Body>
      </Modal>
      </div> 
      <LoadingComponent isShow = {isLoading}/>
    </>
  );
    }
    else{
        return (
            <>
              <Modal show={props.isShow} onHide={props.isClose} className='modal_outer right_modal fade mobileLoginModal'>
                <Modal.Header closeButton>
                  <Modal.Title>Registration</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                <Formik
                        initialValues={initialValues}
                        validationSchema={validationSchema}
                        onSubmit={handleSubmit}
                        >
                <FormikForm>
                <div className="form-group">
                                <label htmlFor='name'>Name&nbsp;<span className='requiredField'>*</span></label>
                                <Field type="text" className="form-control" id='name' name='name' placeholder="Name"/>
                                <ErrorMessage name='name' component={TextError}/>
                </div>
                <div className='clearfix'></div>
                <div className="form-group">
                                <label htmlFor='email'>Email&nbsp;<span className='requiredField'>*</span></label>
                                <Field type="email" className="form-control" id='email' name='email' placeholder="Email"/>
                                <ErrorMessage name='email' component={TextError}/>
                </div>
                <div className='clearfix'></div>
                <div className="form-group">
                                <label htmlFor='mobile'>Mobile Number&nbsp;<span className='requiredField'>*</span></label>
                                <Field type="text" maxlength="12" className="form-control" id='mobile' name='mobile' placeholder="Mobile"/>
                                <ErrorMessage name='mobile' component={TextError}/>
                </div>
                <div className='clearfix'></div>
                <div className="form-group">
                                <label htmlFor='password'>Password&nbsp;<span className='requiredField'>*</span></label>
                                <Field type="password" className="form-control" id='password' name='password' placeholder="Password"/>
                                <ErrorMessage name='password' component={TextError}/>
                </div>
                <div className='clearfix'></div>
                <div className="form-group">
                                <label htmlFor='confirm password'>Confirm Password&nbsp;<span className='requiredField'>*</span></label>
                                <Field type="password" className="form-control" id='confirmPassword' name='confirmPassword' placeholder="Confirm Password"/>
                                <ErrorMessage name='confirmPassword' component={TextError}/>
                </div>
                <div className='clearfix'></div>
                    <button className="qtRegisterBtn">Signup</button>
                    <div className='clearfix'></div>
                    <div className='align-center'>
                    <p>By creating an account I have read and agree to Cadbench's Terms and Privacy Policy</p>
                    <div>
                        Already a Member? <Link className='qtReLogin' onClick={showLogin}>Log In</Link>
                    </div>
                    </div>

                    <ReCAPTCHA
                                        ref={recaptchaRef}
                                        className='googleRecaptcha'
                                        size="invisible"
                                        sitekey="6LdeOcwnAAAAAM9_wGkOuaERsvl0Ks0VX3Dr2crR"
                                        // onChange = {(response)=>captchaOnChange(response)}
                                        
                                    />      
                  </FormikForm>
                  </Formik>
                </Modal.Body>
                
              </Modal>
              <LoadingComponent isShow = {isLoading}/>
            </>
          );
    }
  }

  export default ModalDialog;