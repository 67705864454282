import programimg from '../../images/program.png'
import { Link } from 'react-router-dom';
const MasterComponent = () => {
    return(
        <div>
            <div className="qtMasterMain">
                <div className="container">
                    <div className="qtMasterMains">
                        <div className="row">
                            <div className="col-md-6">
                                <div className="qtMasterContent">
                                    <div >
                                        Cadbench for your<br/>
                                        <span>Master Mind</span>
                                        
                                    </div>
                                </div>
                                <div className="qtMasterMainContents">
                                <p>A curated interactive space for aspiring programmers to learn and master their coding skills.</p>
                                </div>
                                <div className="qtMasterBtn">
                                   <Link to="/courses"><span>Start Learning Now <i className="fa fa-long-arrow-right"></i></span></Link> 
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className='qtMasterImg'>
                                    <img src={programimg} alt="QTree Tech"/>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default MasterComponent;