import aboutus from '../../../images/aboutus.jpg';
import useDocumentTitle from '../../useDocumentTitle';
import SeoComponent from '../../helmet/SeoComponent';
const AboutUsComponent = () => {
    useDocumentTitle("Cadbench Home Page");
    return(
        <>
            <SeoComponent title="About Us - Cadbench Online Training Institute" keywords="Best online cad training,Online Cad Courses, Cad classes online,Cad training online,learn cad online" description="Cadbench offers live, Interactive CAD software training. Explore engaging courses to enhance your skills and performance with effective learning methods."/>
            <div className="qtreeAboutUs">
                <div className="container">
                <div className="qtMainHeads textAlignLeft">
                    <div className="container">
                    <h4>About Us</h4>
                    <div className='qtHrSmallBorder'>
                    <div className='qtHrLineBorder'></div>
                    </div>
                    </div>
                </div>
                <div className='clearfix'></div>
                    <div className="row">
                        <div className="col-md-3">

                            <div className="qtAboutImg">
                                    <img src={aboutus} width="100%" alt="Cadbench"/>
                            </div>

                        </div>
                        <div className="col-md-9">
                            <div className='qtAboutMainContent'>
                                <p>
                                Cadbench is a live, interactive platform for CAD Software training outfitting characters with the fleeting way into prosperous various courses. Made on the possibility that anybody ought to can lead a refreshed corporate life, we have created many thousands of hours, of remarkable, smart, and top of all straightforward instructional exercises that convey the students with overall top-of-the-line abilities. With your course fulfillment with Cadbench, you can verifiably affect your work.</p>
                                <p>Courses are uncommonly curated by very experienced nerdy specialists thus we can answer the altering and adjust to changes and necessities from the business!</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            
        </>
    );
}
export default AboutUsComponent;