import logo from '../../logos.png'
import solid from '../../images/solid.png'
import { Link } from 'react-router-dom';
import React from 'react';
import ModalDialog from '../login/ModalDialog';
import { useState } from 'react';
import { useSelector,useDispatch } from 'react-redux';
import { logout } from '../actions';
import { TextField } from '@mui/material';
import {Autocomplete} from '@mui/material';
import { useEffect } from 'react';
import { NavLink } from 'react-router-dom';
import MobileMenu from '../menu/MobileMenu';
import { Rating } from 'react-simple-star-rating'
import { ToastContainer } from 'react-toastify';
// import { MegaMenu } from 'primereact/megamenu';



const HeaderComponent = (props) =>{

    const { usersData, isLogin, cartCount } = useSelector((state) => state.LoginLogout);
    const dispatch = useDispatch();
    const [myOptions, setMyOptions] = useState([])
    const [myCart,setMyCart] = useState(0);

    const [show, setShow] = useState(false);
    const[courses,setCourses] = useState([]);
    const[learning,setLearning] = useState([]);
    const [isNavExpanded, setIsNavExpanded] = useState(false)
    const [isMobileSHow,setIsMoileShow] = useState(false)
    

    const items = [
        {
            label: 'Videos', icon: 'pi pi-fw pi-video',
            items: [
                [
                    {
                        label: 'Video 1',
                        items: [{label: 'Video 1.1'}, {label: 'Video 1.2'}]
                    },
                    {
                        label: 'Video 2',
                        items: [{label: 'Video 2.1'}, {label: 'Video 2.2'}]
                    }
                ],
                [
                    {
                        label: 'Video 3',
                        items: [{label: 'Video 3.1'}, {label: 'Video 3.2'}]
                    },
                    {
                        label: 'Video 4',
                        items: [{label: 'Video 4.1'}, {label: 'Video 4.2'}]
                    }
                ]
            ]
        },
        {
            label: 'Users', icon: 'pi pi-fw pi-users',
            items: [
                [
                    {
                        label: 'User 1',
                        items: [{label: 'User 1.1'}, {label: 'User 1.2'}]
                    },
                    {
                        label: 'User 2',
                        items: [{label: 'User 2.1'}, {label: 'User 2.2'}]
                    },
                ],
                [
                    {
                        label: 'User 3',
                        items: [{label: 'User 3.1'}, {label: 'User 3.2'}]
                    },
                    {
                        label: 'User 4',
                        items: [{label: 'User 4.1'}, {label: 'User 4.2'}]
                    }
                ],
                [
                    {
                        label: 'User 5',
                        items: [{label: 'User 5.1'}, {label: 'User 5.2'}]
                    },
                    {
                        label: 'User 6',
                        items: [{label: 'User 6.1'}, {label: 'User 6.2'}]
                    }
                ]
            ]
        },
        {
            label: 'Events', icon: 'pi pi-fw pi-calendar',
            items: [
                [
                    {
                        label: 'Event 1',
                        items: [{label: 'Event 1.1'}, {label: 'Event 1.2'}]
                    },
                    {
                        label: 'Event 2',
                        items: [{label: 'Event 2.1'}, {label: 'Event 2.2'}]
                    }
                ],
                [
                    {
                        label: 'Event 3',
                        items: [{label: 'Event 3.1'}, {label: 'Event 3.2'}]
                    },
                    {
                        label: 'Event 4',
                        items: [{label: 'Event 4.1'}, {label: 'Event 4.2'}]
                    }
                ]
            ]
        },
        {
            label: 'Settings', icon: 'pi pi-fw pi-cog',
            items: [
                [
                    {
                        label: 'Setting 1',
                        items: [{label: 'Setting 1.1'}, {label: 'Setting 1.2'}]
                    },
                    {
                        label: 'Setting 2',
                        items: [{label: 'Setting 2.1'}, {label: 'Setting 2.2'}]
                    },
                    {
                        label: 'Setting 3',
                        items: [{label: 'Setting 3.1'}, {label: 'Setting 3.2'}]
                    }
                ],
                [
                    {
                        label: 'Technology 4',
                        items: [{label: 'Setting 4.1'}, {label: 'Setting 4.2'}]
                    }
                ]
            ]
        }
    ]

    const getMyLearning = () => {
        
        if(isLogin === true)
        {
            let URL = window.API_URL+"getUserCourses/"+usersData.id;
                fetch(URL).then((response) => {
                return response.json()
                }).then((res) => {
                   
                    setLearning(res.orders);
                    
                })
        }
    }

    const getAllCourses = () => {
        
        let url = window.API_URL+"menu";
        fetch(url, {
          method: "get",
        })
        .then(response => response.json()
        )
        .then(data => 
          {
            setCourses(data)
        }
        )
        .catch(err => console.log(err))
      }

    const getCartCount = () => {
        fetch(window.API_URL+'getCartCount/'+usersData.id).then((response) => {
          return response.json()
        }).then((res) => {
            setMyCart(res.count)
        })
    }

    const profileClick = (e) => {

        let c = document.querySelector('.profileMenu')
        if(c.style.display === "none")
        {
            c.style.display = "block";
        }
        else
        {
            c.style.display = "none";

        }
        
    }

    const getSearchText = (text) => {
        if(text === null)
            return;
        let searchText = JSON.parse(JSON.stringify(text));
        window.location.replace(window.SITE_URL+"courses?search="+searchText.course_tile);
    }

    const handleLogout = () =>{
        dispatch(logout());
        window.location.replace(window.SITE_URL);
    }

    const handleClose = () => 
    {
        setShow(false);
    }
    const handleShow = () => setShow(true);

    if(isLogin === true && show === true)
    {
        setShow(false)
    }

    const loginMobile = () =>{
        if(props.isHome === "false")
        {
            return ""
        }
        else
        {
         if(isLogin === true)
         {
            return "";
         }
            else{
            return <Link className='login' onClick={handleShow}>Login</Link>
            }
         }
    }

    const renderImageView = (title,iconURL) => {
        
        if(iconURL === "")
        {
            return(
                <div className='titleSpan'>
                    {title.charAt(0)}
                </div>
            )
        }
        else
        {
            return(
                
                <img src={window.IMG_URL+iconURL} width="50px" height="50px" alt={title}/>
            )
        }
    }

    const loginState = () =>{
        
        if(props.isHome === "false")
        {
            
            return ""
        }
        else
        {
         if(isLogin === true)
         {
            //  return <Link className='login' onClick={handleLogout}>Logout</Link>
            return "";
         }
            else{
            return <Link className='login dLogin' onClick={handleShow}>Login</Link>
            }
         }
    }

    const changeLocation = (slug) => {
        window.location.href = window.SITE_URL+"courses/"+slug
    }


    const getDataFromAPI = (e) => {
        if(e.target.value === "")
            return;
        setMyOptions([]);
        fetch(window.API_URL+'searchcourse/'+e.target.value).then((response) => {
          return response.json()
        }).then((res) => {
            setMyOptions(res)
        })
      }

    const removeTraining = (course) =>{
        return course.replace('Training', '');
    }  

      useEffect(() => {
       
        getAllCourses()
        getMyLearning()
        
    }, [])
    return(
        <>
            <ToastContainer/>
            <MobileMenu isShow={isMobileSHow} isHide={()=>setIsMoileShow(false)} courses={courses} isLogin={isLogin} loginState ={loginMobile} cartCount = {cartCount} handleLogout={handleLogout}/>
            <ModalDialog isShow={show} isClose={handleClose}/>

            {/* <MegaMenu model={items}/> */}

            {/* <NavigationComponent/> */}
            <div className='trueHeader'>
                <div className='container'>
            <nav className="navigation trueHeader">
                <span href="/" className="brand-name">
                <i className="fa fa-mobile">&nbsp;+91 9500210134</i>
                </span>

                <span href="/" className="brand-name">
                <i className="fa fa-envelope">&nbsp;info@cadbench.com</i>
                </span>
                    <button className="hamburger"
                    onClick={() => {
                        setIsMoileShow(true)
                    }}
                    >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          className="h-5 w-5"
          viewBox="0 0 20 20"
          fill="white"
        >
          <path
            fillRule="evenodd"
            d="M3 5a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 10a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM9 15a1 1 0 011-1h6a1 1 0 110 2h-6a1 1 0 01-1-1z"
            clipRule="evenodd"
          />
        </svg>
      </button>
      <div
        className={
            isNavExpanded ? "navigation-menu expanded" : "navigation-menu"
          }>
        <ul className='cMenu'>
                                {
                                  courses.length > 0 && (
                                        <li className='sub-menu'>
                                            <NavLink  to="/courses">Our Courses</NavLink >
                                            <div className='mega-menu'>
                                                <div className='content container-fluid'>
                                                    <div className='row'>
                                                {courses.map((category,index) => (
                                                                <div className='col-md-3 rows' key={index}>
                                                                <h2 className='title'>{category.name}</h2>
                                                                <ul className='mega-menu-links'>
                                                                    <li>

                                                                    {
                                                                        category.course.map((course,i)=>(
                                                                            <NavLink key={i}  onClick={()=>changeLocation(course.slug)}>{removeTraining(course.course_tile)}</NavLink >
                                                                        ))
                                                                    }

                                                                    </li>
                                                                </ul>
                                                            </div>
                                                        ))}
                                                        </div>
                                                </div>
                                            </div>
                                        </li>
                                    ) 


                                }

                                    {
                                        (isLogin === true && learning.length > 0) &&
                                        <li className='learnMenu'>
                                            <NavLink>My Learning</NavLink >
                                            <div className='learning-menus'>
                                                <div className='content'>
                                                <ul className='learningMenus'>
                                                    {
                                                        learning.map((order,i) => 
                                                        (
                                           
                                                    
                                                        order.orderdetail.map((orderdetail,index) => (
                                                            <li key={index}>
                                                                    <div className='cadLearnMenu' key={index}>
                                                                            <div className='row'>
                                                                            
                                                                            
                                                                                            <div className='sMenuLeft col-md-3'>
                                                                                                {
                                                                                                    renderImageView(orderdetail.ordercourse.course_tile,orderdetail.ordercourse.icon_url)
                                                                                                }
                                                                                            
                                                                                            </div>
                                                                                            <div className='sMenuRight col-md-9'>
                                                                                            {orderdetail.ordercourse.course_tile}
                                                                                        
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        </li>
                                                                
                                                                    )
                                                        )
                                                        
                         
                                                ))
                                                    }
                                                </ul>
                                                <div className='cadAllLearning'>
                                                <NavLink to="/mycourses">Go To My Learning</NavLink> 
                                                </div>
                                                </div>
                                            </div>
                                        </li>
                                    }

                                        <li>
                                        <NavLink  to="/about">About Us</NavLink >
                                        </li>
                                        <li>
                                            
                                        <NavLink  to="/contact">Contact Us</NavLink >
                                        </li>
                                        {
                                            isLogin === true &&
                                        <li>
                                            <NavLink  to="/mycart"><i className="fa fa-shopping-cart" aria-hidden="true"></i>
                                            {
                                                cartCount > 0 &&
                                            <span className='myCart'>{cartCount}</span>
                                            }
                                            </NavLink >
                                        </li>
                                        }
                                        {
                                            isLogin === true &&
                                       
                                        // <li className='profileIcon' 
                                        // onClick={(e)=>profileClick(e)}
                                        //  >
                                        <li className='profileIcon'>
                                            <span>{usersData.name.charAt(0)}</span>
                                            <div className='profile-menu'>
                                                <div className='content'>
                                                <ul className='profileMenus'>
                                                    <li><NavLink  to="/myprofile">My Profile</NavLink ></li>
                                                    <li><NavLink  to="/mycourses">My Courses</NavLink ></li>
                                                    <li><NavLink  to="/mywishlist">My Wishlists</NavLink ></li>
                                                    <li><NavLink  onClick={handleLogout}>Logout</NavLink ></li>
                                                </ul>
                                                </div>
                                            </div>
                                        </li>
                                         }
        </ul>
      </div>
    </nav>

    </div>
    </div>

                    <div className='topHeader'>
                        <div className="container">
                            <div className='row'>
                                <div className="col-md-6">
                                <div className="row">
                                        <div className="col-md-4 col-6">
                                            
                                            <Link to="/"><img  className='wow fadeInLeft animate animated' src={logo} alt='QTree' width="100%" data-wow-delay="0.5s"/></Link>
                                            
                                        </div>
                                        <div className='col-md-8 col-6'>
                                            <Autocomplete
                                            disablePortal
                                            onChange={(event, newValue) => {
                                               
                                                    getSearchText(newValue)
                                            }}
                                            style={{ width: 500 }}
                                            freeSolo
                                            autoComplete
                                            autoHighlight
                                            options={myOptions}
                                            
                                            sx={{
                                                '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                                                border: 'none',
                                                },
                                        }}
                                            getOptionLabel={(option) => option.course_tile}
                                            renderInput={(params) => (
                                            <TextField {...params}
                                                onChange={(e)=>getDataFromAPI(e)}
                                                onKeyDown={(e)=>{
                                                    if(e.key === 'Enter')
                                                    {
                                                        e.stopPropagation();
                                                    }
                                                }}
                                               
                                            />
                                            )}
                                        />


                                        </div>
                                </div>
                                </div>
                                <div className="col-md-6">
                                        <div>
                                                <nav className='linksRight'>
                                                    {
                                                        loginState()
                                                    }
                                                   
                                                </nav>
                                        </div>
                                </div>
                            </div>
                        </div>
                    </div>
       

        </>
    )
}
export default HeaderComponent;