import { Helmet } from "react-helmet-async"
const SeoComponent = ({title, description,keywords}) => {
    return(
        <>
            <Helmet>
            <title>{title}</title>
            <meta name='description' content={description} />
            <meta name="keywords" content={keywords}></meta>
            <meta name="author" content="Cadbench"></meta>
            {/* <link rel="canonical" href="https://cadbench.com/" /> */}
            </Helmet>
        </>
    )
}
export default SeoComponent