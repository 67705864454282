//const initialState = false

const initialState = {
    usersData: [],
    isLogin: false,
    cartCount:0,
    currency:"INR",
    currencyValue:0
  };

const LoginLogout = (state=initialState,actions) =>{
  
    switch(actions.type)
    {
        
        case "LOGIN":
            return {
                ...state,
                usersData: actions.usersData,
                isLogin: true,
                cartCount:actions.cartCount,
                currency:actions.currency,
                currencyValue:actions.currencyValue,
              };
        case "LOGOUT":
            return {
                ...state,
                usersData: [],
                isLogin: false,
                cartCount:actions.cartCount,
                currency:actions.currency,
                currencyValue:actions.currencyValue,
              };
        case "CART":
              return {
                  ...state,
                  usersData: actions.usersData,
                  isLogin: true,
                  cartCount:actions.cartCount,
                  currency:actions.currency,
                  currencyValue:actions.currencyValue,
                };    
        case "CURRENCY":
          return {
            ...state,
            usersData: actions.usersData,
            isLogin: actions.isLogin,
            cartCount:actions.cartCount,
            currency:actions.currency,
            currencyValue:actions.currencyValue,
          }; 

        default:
            return state;
    }
    
}

export default LoginLogout;