import { useRef } from 'react';
import TawkMessengerReact from '@tawk.to/tawk-messenger-react';
const TawkMessengerComp = () =>{
    const tawkMessengerRef = useRef();

    const handleMinimize = () => {
        tawkMessengerRef.current.minimize();
    };

    return (
        <div className="App">
            <button onClick={handleMinimize}> Minimize the Chat </button>

            <TawkMessengerReact
                propertyId="649d5e46cc26a871b0255902"
                widgetId="1h43bgi49"
                ref={tawkMessengerRef}/>
        </div>
    );
}
export default TawkMessengerComp;