import { NavLink } from 'react-router-dom';
import { ModalBody } from 'react-bootstrap';
import Modal from 'react-bootstrap/Modal';
const MobileMenu = (props) => {
    return(
        <>
            <div className='mobileMenuModal'>
            <Modal 
            show={props.isShow} 
            className='modal_outer right_modal fade mobileMenuModal'
            {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      
            >
            <Modal.Header>
                <Modal.Title className='cursor' onClick={props.isHide}
                >Close</Modal.Title>
            </Modal.Header>
            <ModalBody>
                    <div>
                    <ul className='cadMobileMenu'>
                        <li>
                            <NavLink  to="/courses">Our Courses</NavLink >
                                    {/* {props.courses.length > 0 && (
                                                    <ul className='cadSMobileMenu'>
                                                        {props.courses.map((course,index) => (
                                                                <li key={index}>
                                                                    <NavLink  to={{pathname:"/courses/"+course.slug, state: {course}}}>
                                                                    {
                                                                        course.course_tile
                                                                    }
                                                                    </NavLink >
                                                                </li>
                                                        ))}
                                                        </ul>
                                                    )} */}
                        </li>
                        <li>
                                        <NavLink  to="/about">About Us</NavLink >
                                        </li>
                                        <li>
                                            
                                        <NavLink  to="/contact">Contact Us</NavLink >
                                        </li>

                                        {
                                            props.isLogin === true &&
                                        <li>
                                            <NavLink  to="/mycart"><i class="fa fa-shopping-cart" aria-hidden="true"></i> My Cart - 
                                            {
                                                props.cartCount > 0 &&
                                            <span className='myCartMobile'>{props.cartCount}</span>
                                            }
                                            </NavLink >
                                        </li>


                                        }

                                        {
                                            props.loginState()
                                        }



                                        {
                                            props.isLogin === true &&
                                                <>
                                                <li className='cadUserRow'>My Account</li>
                                                <li><NavLink  to="/myprofile">My Profile</NavLink ></li>
                                                <li><NavLink  to="/mycourses">My Courses</NavLink ></li>
                                                <li><NavLink  to="/mywishlist">My Wishlists</NavLink ></li>
                                                <li><NavLink  onClick={props.handleLogout}>Logout</NavLink ></li>
                                            </>

                                            
                                        }

                                        
                    </ul>


                    {/* <ul className='cMenu'>
        <li className='courseMenu'>
                                            <NavLink  to="/courses">Our Courses</NavLink >

                                            {props.courses.length > 0 && (
                                                    <ul className='sMenu'>
                                                        {props.courses.map((course,index) => (
                                                                <li>
                                                                    <NavLink  to={{pathname:"/courses/"+course.slug, state: {course}}}>
                                                                    {
                                                                        course.course_tile
                                                                    }
                                                                    </NavLink >
                                                                </li>
                                                        ))}
                                                        </ul>
                                                    )}
                                            
                                        </li>
                                        <li>
                                        <NavLink  to="/about">About Us</NavLink >
                                        </li>
                                        <li>
                                            
                                        <NavLink  to="/contact">Contact Us</NavLink >
                                        </li>
                                        {
                                            props.isLogin === true &&
                                        <li>
                                            <NavLink  to="/mycart"><i class="fa fa-shopping-cart" aria-hidden="true"></i>
                                            {
                                                props.cartCount > 0 &&
                                            <span className='myCart'>{props.cartCount}</span>
                                            }
                                            </NavLink >
                                        </li>
                                        }
                                        {
                                            props.isLogin === true &&
                                       
                                        <li className='profileIcon'>
                                        
                                            <ul className='profileMenu'>
                                                <li><NavLink  to="/myprofile">My Profile</NavLink ></li>
                                                <li><NavLink  to="/mycourses">My Courses</NavLink ></li>
                                                <li><NavLink  to="/mywishlist">My Wishlists</NavLink ></li>
                                                <li><NavLink  onClick={props.handleLogout}>Logout</NavLink ></li>
                                            </ul>
                                        </li>
                                         }
        </ul> */}
                    </div>
            </ModalBody>
            </Modal>
            </div>
        </>
    )
}

export default MobileMenu;