import Modal from 'react-bootstrap/Modal';
const CertificateModal = (props) => {
    return(
        <Modal show={props.isShow}
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      onHide={props.isHide}
    >
      <Modal.Header closeButton>
          <Modal.Title>Sample Certificate Copy</Modal.Title>
    </Modal.Header>
      <Modal.Body className='show-grid'>
      
        <div>
            <img src={props.cert} width="100%" alt='Cadbench'/>
        </div>
            
        
      </Modal.Body>
    </Modal>
    )
}
export default CertificateModal