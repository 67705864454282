import HeaderComponent from "../header"
import FooterComponent from "../footer"
import TrueFooterComponent from "../TrueFooter"
import ContactBannerComponent from "../contact/banner"
import SeoComponent from "../helmet/SeoComponent"
import {
    Accordion,
    AccordionItem,
    AccordionItemHeading,
    AccordionItemButton,
    AccordionItemPanel,
} from 'react-accessible-accordion';
import 'react-accessible-accordion/dist/fancy-example.css';
const CancelRefund = () => {
    return(
        <>  
            <SeoComponent title="Cancel Refund Policy - Cadbench Online Training Institute" keywords="Best online cad training,Online Cad Courses, Cad classes online,Cad training online,learn cad online" description="Cadbench offers live, Interactive CAD software training. Explore engaging courses to enhance your skills and performance with effective learning methods."/>
            <HeaderComponent isHome="false"/>
            <ContactBannerComponent title="Cancellation & Refunds"/>
            <div className="container textAlignLeft oPagesContainer">
            <Accordion allowZeroExpanded="true">
            
            <AccordionItem>
                <AccordionItemHeading>
                    <AccordionItemButton>
                    Refund Policy
                    </AccordionItemButton>
                </AccordionItemHeading>
                <AccordionItemPanel>
                <div className="qtTitleAccordian">
                    <p>
                    Thank you for buying our courses. We ensure that our users have a rewarding experience while they discover, assess, and purchase our courses, whether it may be instructor-led or self-paced training or classroom training.
                    </p>
                    <p>
                    As with any online purchase experience, there are terms and conditions that govern the Refund Policy. When you buy a training course on www.cadbench.com, you agree to our Privacy Policy, Terms of Use, and refund policy.
                    </p>
                    </div>
                </AccordionItemPanel>
            </AccordionItem>

            <AccordionItem>
                <AccordionItemHeading>
                    <AccordionItemButton>
                    Our refund policy is as follows
                    </AccordionItemButton>
                </AccordionItemHeading>
                <AccordionItemPanel>
                <div className="qtTitleAccordian">
                <strong>Cancellation & Refunds: Self Placed Learning:</strong>
                <p>For Self-Placed Learning:</p>
                <strong>No refunds.</strong> All payments under this purchase will be irrevocable, non-refundable, and non-creditable.
                <p>For Instructor Led Online Training:</p>
                <strong>No Refunds.</strong> There will be no refunds whatsoever for any courses.
                <p>Cancellation & Refunds: Classroom Training:</p>
                <p>No Refunds. Payments referred to herein shall not be refundable under any circumstances. All payments under this purchase will be irrevocable, non-refundable, and non-creditable.</p>
                <p>Cadbench reserves the right to postpone/cancel an event, or change the location of an event because of insufficient enrolments, instructor illness, or force majeure events (like floods, earthquakes, political instability, etc)</p>
                <p>In case Cadbench cancels an event, a 100% refund will be paid to the delegate.</p>
                <strong>Refunds:</strong> Duplicate payment
                <p>Refunds of the duplicate payment made by the delegate will be processed via the same source (original method of payment) in 7 to 10 working days post intimation by the customer.</p>
                <strong>Note:</strong> All refunds will be processed within 7-10 working days after the refund request is approved by Cadbench

                    </div>
                </AccordionItemPanel>
            </AccordionItem>

            </Accordion>
            </div>
            <FooterComponent/>
            <TrueFooterComponent/>
        </>
    )
}
export default CancelRefund