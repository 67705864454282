import LoadingComponent from "../loading";
import { useState } from "react";
import { toast} from 'react-toastify';
import { Formik,Field,Form,ErrorMessage } from "formik";
import * as Yup from 'yup'
import TextError from "../error/TextError";
const ApplyComponent = (props) =>{
  const[isLoading,setLoading] = useState(false);
  const phoneRegExp = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/ 
  const emailRegExp = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/
  const initialFormValues = {
    name:'',
    email:'',
    mobile:''
  }

  const validationSchema = Yup.object(
    {
        name:Yup.string().required('Required!'),
        email:Yup.string().matches(emailRegExp,'Invalid email format!').required('Required!'),
        mobile: Yup.string().required('Required').matches(phoneRegExp, 'Mobile Number is not valid').min(10),
    });

  const handleSubmit = async(values) => 
  {
      setLoading(true)
      const token = await props.captchaRef.current.executeAsync(); 
      var myHeaders = new Headers();
      const datas = new FormData();
      datas.append("name", values.name);
      datas.append("email", values.email);
      datas.append("mobile", values.mobile);
      datas.append("enquiry_for","LIKE_COURSE");
      datas.append("g-recaptcha-response",token);
      let url = window.API_URL+"enquiry";
      fetch(url, {
          "crossDomain": true,
          method: "POST",
          body: datas,
          headers: myHeaders,
          "processData": false,
          "contentType": false,
        })
        .then(response => response.json()
        )
        .then(data => 
          {
            props.captchaRef.current.reset();
            if(data[0]['data']['success'] === 1)
            {
                toast.success("Thanking You!", {
                    position: toast.POSITION.TOP_RIGHT
                  });
                values.name=""
                values.email = ""
                values.mobile = ""
            }
            else{
                toast.error("Unable to submit! Please Try Again!", {
                    position: toast.POSITION.TOP_RIGHT
                  });
            }
            setLoading(false)
        }
        )
        .catch(err => {
            props.captchaRef.current.reset();
            toast.error("Unable to submit! Please Try Again!", {
                position: toast.POSITION.TOP_RIGHT
              });
              setLoading(false)
        })
  }
    return(
            <>
            
             <LoadingComponent isShow = {isLoading}/>  
            <div className="qtCourseApply">
                <div className="qtCourseBox">
                    <div className="qtApplyTitle textAlignLeft">
                        <h1>Like the curriculam?</h1>
                        <h3>Apply Now</h3>
                    </div>
                    <div className="qtApplyForm">

                    <Formik
                        initialValues={initialFormValues}
                        validationSchema={validationSchema}
                        onSubmit={handleSubmit}
                    >
                        <Form>
                        <div className="form-group">
                            <Field type="text" 
                            className="form-control" 
                            id='name'
                            name='name'
                            placeholder="Name"/>
                            <ErrorMessage name='name' component={TextError}/>
                        </div>

                        <div className="clearfix"></div>

                        <div className="form-group">
                            <Field type="email"
                            className="form-control" 
                            id='email'
                            name='email'
                            placeholder="Email"/>
                            <ErrorMessage name='email' component={TextError}/>
                        </div>

                        <div className="clearfix"></div>

                        <div className="form-group">
                            <Field type="text"  
                            className="form-control" 
                            id='mobile'
                            name='mobile'
                            maxLength="12" 
                            placeholder="Mobile Number"/>
                            <ErrorMessage name='mobile' component={TextError}/>
                        </div>

                        <div className="clearfix"></div>

                        <div className="qtCourseButtons">
                                <button className="submit">Apply Now</button>
                               
                        </div>
                        </Form>
                    </Formik>    
                        
                    </div>
                </div>
            </div>
            </>
    )
}
export default ApplyComponent