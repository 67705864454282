
import { useState,useEffect } from "react";
import parse from 'html-react-parser';
import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'
const DesignationComponent=(props)=>{
    const[designation,setDesignation] = useState([]);
    const getAllData = () => {
        let URL = window.API_URL+"course/getCourseDetails/"+props.info.id;
        fetch(URL, {
          
          method: "get",
          headers: {
			'TXNTYPE': 'DESIGNATIONS',
		},
        })
        .then(response => response.json()
        )
        .then(data => 
          {
           
            setDesignation(data)
        }
        )
        .catch(err => console.log(err))
      }

      const setData = () =>{
        if(designation.length > 0)
        {
            return(
                <ul>
                {designation.map((course,index) => (
                        <li key={index}>{parse(course.description)}</li>
                 ))}
                </ul>  
            )
        }
        else
        {
            return(
                <ul>
                                    <li><Skeleton variant="text" count={1}/></li>
                                    <li><Skeleton variant="text" count={1}/></li>
                                    <li><Skeleton variant="text" count={1}/></li>
                                    <li><Skeleton variant="text" count={1}/></li>
                                </ul>
            )
        }
      }
    

  useEffect(() => {
    getAllData()
  }, [])
    return(
        <>
            <div className="qtDesignation">
                <div className="container">
                    <div className="row">
                        <div className="col-md-4">
                            <h3>Designations</h3>
                        </div>
                        <div className="col-md-8">
                            <div className="qtDesContent">
                                {
                                    setData()
                                }
                            {/* {designation.length > 0 && (
                            <ul>
                            {designation.map((course,index) => (
                                    <li>{parse(course.description)}</li>
                             ))}
                            </ul>
                         )} */}

                                {/* <ul>
                                    <li>Drafter</li>
                                    <li>Engineer</li>
                                    <li>Architect</li>
                                    <li>Designer</li>
                                </ul> */}
                            </div>
                        </div>
                    </div>

                    <div className="row">
                        
                            <div className="col-md-12">
                            <div className="qtDesSalarys">
                            <div className="qtDesSalary">
                                <div className="qtTitle">
                                    <h2>Annual Salary of {props.info.course_tile}</h2>
                                </div>
                                <div className="qtDesSalContent">
                                    Salary for S{props.info.course_tile} starts from
                                    <div className="clearfix"></div>
                                    <div className="qtCouTitle">
                                        <h2> <i className="fa fa-inr" aria-hidden="true"></i> 1,10,000 to 2,80,000 / Annum</h2>
                                    </div>
                                </div>
                            </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
export default DesignationComponent