import { useEffect,useState } from "react";
import LoadingComponent from "../loading";


const ShortLinkComponent = () => {
    
    const[isLoading,setLoading] = useState(false);
    useEffect(() => {
        const URL = window.location.href;
        const segment = URL.substring(URL.lastIndexOf('/') + 1);
        setLoading(true)
            let url = window.API_URL+segment;
                fetch(url).then((response) => {
                return response.json()
                }).then((res) => 
                {
                    if(res !== null)
                    {
                        window.location.href = res.link;
                    }
                    else
                    {
                        window.location.replace(window.SITE_URL);
                    }
                    setLoading(false)
                })

      }, [])


    return(
        <>
            <LoadingComponent isShow = {isLoading}/>
            
        </>
    )
}
export default ShortLinkComponent;