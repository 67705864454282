import reactjs from '../../images/reactjs.png'
import HeaderComponent from "../header";
import FooterComponent from "../footer";
import { useSelector,useDispatch } from 'react-redux';
import { useEffect, useState } from 'react';
import LoadingComponent from '../loading';
const WishListComponent = () => {
    const { usersData, isLogin} = useSelector((state) => state.LoginLogout);
    const [course,setCartCourses] = useState([]);
    const [wishTotal,setWishTotal] = useState(0);
    const[isLoading,setLoading] = useState(false);

    const deleteCart = (id,index) => {
        setLoading(true)
            let URL = window.API_URL+"deleteWishList/"+id;
            fetch(URL).then((response) => {
                return response.json()
                }).then((res) => {
                   if(res[0].data.success === 1)
                   {
                    
                    setCartCourses((current) =>
                        current.filter((cart) => cart.id !== id)
                    );
                    let total = wishTotal-1;
                    if(total<0)
                        setWishTotal(0)
                    else
                        setWishTotal(total);    
                   }
                   else
                   {
                        console.log("Unable to deleted");
                   }
                   setLoading(false)
                    
                })

    }

    const getMyCourses = () => {

        if(isLogin === true)
        {
            setLoading(true)
            let URL = window.API_URL+"getWishlistByUser/"+usersData.id;
                fetch(URL).then((response) => {
                return response.json()
                }).then((res) => {
                    
                    setWishTotal(res.length);
                    setCartCourses(res);
                    setLoading(false)
                })
        }
    }
    
    useEffect(() => {
        getMyCourses();
      }, [])
    

    if(isLogin !== true)
    {
        window.location.replace(window.SITE_URL);
        console.log("You have to login first");
    }
    else
    {

    return(
        <>
            <LoadingComponent isShow = {isLoading}/>
            <HeaderComponent isHome="false"/>
            
            <div className="container">
            <div className="cadCartContainer">
                <div className="cadCartHeading">
                <h3>My Wishlist</h3>
                </div>
                <div className="row">
                    <div className="col-md-9">
                    <div className="cadCartCount">
                    {wishTotal} Courses in your Wishlist
                    </div>
                    <div className="hrRule"></div>

                    <div className="cadCartItems">

                    

                    {course.length > 0 && (
                          <div className="row">
                  {course.map((course,index) => (
                    <div key={course.id}>
                        <div className="cadCartItem">
                            <div className='row'>
                                <div className='col-md-2'>
                                    <img src={reactjs} width="100%" height="60px" alt='cadBench'/>
                                </div>
                                <div className='col-md-6'>
                                    <div className='cadCartDetails'>
                                        <h2>{course.courses.course_tile}</h2>
                                    </div>
                                </div>
                                <div className='col-md-2'>
                                    <div className='cadCartOption'>
                                        <ul>
                                            <li onClick={()=>deleteCart(course.id,index)}>Remove&nbsp;<i class="fa fa-trash" aria-hidden="true"></i></li>
                                        </ul>
                                    </div>
                                </div>
                                <div className='col-md-2'>
                                        <div className='cadCartValue'>
                                        <i class="fa fa-inr" aria-hidden="true"></i>&nbsp;{course.courses.fee}
                                        </div>
                                </div>
                            </div>
                        </div>
                        <div className="hrRule"></div>
                        </div>
                  ))}
                  </div>
              )}
                    </div>

                    </div>

                 
                    </div>
            </div>
            </div>
            <FooterComponent/>
        </>
    )
    }

}

export default WishListComponent