import { useSelector,useDispatch } from 'react-redux';
import { useState,useEffect } from "react";
import {Formik,Form,Field,ErrorMessage} from 'formik'
import * as Yup from 'yup'
import { toast} from 'react-toastify';
import LoadingComponent from '../loading';
import { login,logout } from '../actions';
import TextError from '../error/TextError';

const ProfileHomeComponent= () => {
    const[isLoading,setLoading] = useState(false);
    const { usersData, isLogin,cartCount,currency,currencyValue} = useSelector((state) => state.LoginLogout);
    const dispatch = useDispatch();
    const [mode,setMode] = useState("PROFILE");
    const [isSend,setIsSend] = useState(false);
    const initialValues = {
            name:usersData.name,
            email:usersData.email,
            mobile:usersData.mobile
    }

    const passwordRules = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}$/;
    

    const initialChangePwdValues = {
        current_password:"",
        new_password:"",
        confirm_password:""
    }

    const validationChangePasswordSchema = Yup.object(
        {
            current_password: Yup.string().required('Required'),
            new_password:Yup.string()
            .matches(passwordRules, { message: "Please create a stronger password" })
            .required('Required!'),
            confirm_password:Yup
            .string()
            .oneOf([Yup.ref("new_password"), null], "Passwords must match")
            .required("Required!"),
        }
    )

const validationSchema = Yup.object(
    {
        name: Yup.string().required('Required'),
        email:Yup.string().email('Invalid email format').required('Required'),
        mobile:Yup.number().required('Required')
    }
)

const deleteAccount = () => {
    setLoading(true);
    
    var myHeaders = new Headers();
    myHeaders.append("Authorization", 'Bearer '+usersData['token']);
    const url = window.API_URL+"delete"
    fetch(url, {
        method: "POST",
        headers:myHeaders,
      })
      .then(response => {
            return response.json()
      }
      )
      .then(res => 
        {
            if(res.success === 1)
            {
                dispatch(logout());
                window.location.replace(window.SITE_URL);
            }
            else
            {
                toast.error("Unable to delete Account", {
                    position: toast.POSITION.TOP_RIGHT
                  });
            }
          setLoading(false);
        }   
      )
      .catch(err => {
        toast.error("Unable to delete Account", {
            position: toast.POSITION.TOP_RIGHT
          });
        setLoading(false);
      }
        )
}

const verifyEmail = () => {

    setLoading(true);
    
    var myHeaders = new Headers();
    myHeaders.append("Authorization", 'Bearer '+usersData['token']);
    const url = window.API_URL+"email/verification-notification"
    
    fetch(url, {
        method: "POST",
        headers:myHeaders,
      })
      .then(response => {
            return response.json()
      }
      )
      .then(res => 
        {
            if(res.success === 1)
            {
               setIsSend(true); 
            }
            else{
                setIsSend(false); 
            }
          setLoading(false);
        }   
      )
      .catch(err => {
        toast.error("Unable to send email!", {
            position: toast.POSITION.TOP_RIGHT
          });
        setLoading(false);
      }
        )
}

const renderView = () => {
    if(mode === "PROFILE")
    {
        return(
            <>
                <div className="profileRightTop">
                        Profile
                    </div>
                    <div className="profileForms">
                        
                        <Formik
                        initialValues={initialValues}
                        validationSchema={validationSchema}
                        onSubmit={onSubmit}
                        >
                        <Form>

                            <div className="form-group">
                                <label htmlFor='name'>Name&nbsp;<span className='requiredField'>*</span></label>
                                <Field type="text" className="form-control" id='name' name='name' placeholder="First Name"/>
                                <ErrorMessage name='name' component={TextError}/>
                            </div>
                            <div className="clearfix"></div>   
                                <div className="form-group">
                                <label htmlFor='mobile'>Mobile&nbsp;<span className='requiredField'>*</span></label>
                                <Field type="text" className="form-control" maxLength="12" id='mobile' name='mobile' placeholder="Mobile Number"/>
                                <ErrorMessage name='mobile' component={TextError}/>
                            </div>
                            <div className="clearfix"></div>
                            <div className="form-group">
                                <label htmlFor='email'>Email&nbsp;<span className='requiredField'>*</span></label>
                                <Field type="email" className="form-control" id='email' name='email' placeholder="Email"/>
                                <ErrorMessage name='email' component={TextError}/>
                            </div>
                            <div className="clearfix"></div>
                            <div className="qtCourseButtons">
                                <button className="submit">Save</button>
                            </div>
                        </Form>
                        </Formik>
                    </div>
            </>
        )
    }
    else if(mode === "VERIFY_EMAIL")
    {
        return(
            <>
                <div className="profileRightTop">
                        Verify Email
                    </div>
                    <div className="profileForms">
                        
                        <div className='profileHeading'>
                            Verify your email
                        </div>
                        <div className='profileContents'>
                            Hi {usersData.name}, Use this link below to verify your email and start your journey with Cadbench
                        </div>
                        <div className='qtCourseButtons'>
                        <button onClick={()=>verifyEmail()} className='submit'>Verify Email</button>
            
                        </div>
                        {
                            isSend &&(
                                <div className='profileContents'>
                                    Verification email sent to your registered email!. Please verify ASAP.
                                </div>    
                            )
                        }
                        
                    </div>
            </>
        )
    }
    else if(mode === "CHANGE_PASSWORD")
    {
        return(
            <>
                <div className="profileRightTop">
                        Change Password
                    </div>
                    <div className="profileForms">
                        
                        <Formik
                        initialValues={initialChangePwdValues}
                        validationSchema={validationChangePasswordSchema}
                        onSubmit={onPasswordSubmit}
                        >
                        <Form>

                            <div className="form-group">
                                <label htmlFor='text'>Current Password&nbsp;<span className='requiredField'>*</span></label>
                                <Field type="password" className="form-control" id='current_password' name='current_password' placeholder="Enter your current password"/>
                                <ErrorMessage name='current_password' component={TextError}/>
                            </div>
                            <div className="clearfix"></div>   
                                <div className="form-group">
                                <label htmlFor='text'>New Password&nbsp;<span className='requiredField'>*</span></label>
                                <Field type="password" className="form-control" id='new_password' name='new_password' placeholder="Enter your new password"/>
                                <ErrorMessage name='new_password' component={TextError}/>
                            </div>
                            <div className="clearfix"></div>
                            <div className="form-group">
                                <label htmlFor='text'>Confirm Password&nbsp;<span className='requiredField'>*</span></label>
                                <Field type="text" className="form-control" id='confirm_password' name='confirm_password' placeholder="Please re-enter your password"/>
                                <ErrorMessage name='confirm_password' component={TextError}/>
                            </div>
                            <div className="clearfix"></div>
                            <div className="qtCourseButtons">
                                <button className="submit">Update</button>
                            </div>
                        </Form>
                        </Formik>
                    </div>
            </>
        )
    }
    else{
        return(
            <>
                <div className="profileRightTop">
                        Delete Account
                    </div>
                    <div className="profileForms">
                        
                        <div className='profileHeading'>
                            Delete Account
                        </div>
                        <div className='profileContents'>
                            Hi {usersData.name}, If you delete your account, your personal information will be wiped from Cadbench's servers, all of your course activity will be anonymized and any certificates earned will be deleted. This action cannot be undone!
                        </div>
                        <div className='qtCourseButtons'>
                        <button onClick={()=>deleteAccount()} className='submit'>Confirm Delete</button>
            
                        </div>
                        {
                            isSend &&(
                                <div className='profileContents'>
                                    Verification email sent to your registered email!. Please verify ASAP.
                                </div>    
                            )
                        }
                        
                    </div>
            </>
        )
    }
}

const clickMode = (mode) =>{

    setMode(mode);

}



const onSubmit= (values) => {
    setLoading(true);
    
    var myHeaders = new Headers();
    myHeaders.append("Authorization", 'Bearer '+usersData['token']);
    const url = window.API_URL+"updateBasicDetails"
    const datas = new FormData();
    datas.append("email", values.email);
    datas.append("name",values.name);
    datas.append("mobile",values.mobile);
    
    fetch(url, {
        method: "POST",
        body: datas,
        headers:myHeaders,
      })
      .then(response => {
            return response.json()
      }
      )
      .then(res => 
        {
            if(res.success)
            {
            toast.success("Users Updated", {
                position: toast.POSITION.TOP_RIGHT
              });
              dispatch(login(res.data,cartCount,currency,currencyValue)); 
            }
            else{
                toast.error("Unable to update!", {
                    position: toast.POSITION.TOP_RIGHT
                  });
            }
          setLoading(false);
        }   
      )
      .catch(err => {
        toast.error("Unable to update!", {
            position: toast.POSITION.TOP_RIGHT
          });
        setLoading(false);
      }
        )
}

const onPasswordSubmit= (values) => {
    setLoading(true);
    
    var myHeaders = new Headers();
    myHeaders.append("Authorization", 'Bearer '+usersData['token']);
    const url = window.API_URL+"password"
    const datas = new FormData();
    datas.append("current_password", values.current_password);
    datas.append("new_password",values.new_password);
    datas.append("confirm_password",values.confirm_password);
    
    fetch(url, {
        method: "POST",
        body: datas,
        headers:myHeaders,
      })
      .then(response => {
            return response.json()
      }
      )
      .then(res => 
        {
            if(res.success)
            {
            toast.success("Password is Changed", {
                position: toast.POSITION.TOP_RIGHT
              });
              dispatch(logout());
              window.location.replace(window.SITE_URL);  
              //dispatch(login(res.data,cartCount,currency,currencyValue)); 
            }
            else{
                toast.error("Unable to change the password!", {
                    position: toast.POSITION.TOP_RIGHT
                  });
            }
          setLoading(false);
        }   
      )
      .catch(err => {
        toast.error("Unable to update!", {
            position: toast.POSITION.TOP_RIGHT
          });
        setLoading(false);
      }
        )
}


if(isLogin !== true)
{
    window.location.replace(window.SITE_URL);
}

return(
    <div>
        <LoadingComponent isShow = {isLoading}/>
        <div className="container">
            <div className="profileContent">
            <div className="row">
                <div className="col-md-3 profileLeft">
                    
                    <div className="profileHomeTop">
                        {usersData.name}
                    </div>
                    <div className="profileMenu">
                        <ul>
                            <li onClick={()=>setMode("PROFILE")} className ={mode === "PROFILE" && 'plinkActive'}>Profile</li>
                            <li onClick={()=>setMode("VERIFY_EMAIL")} className ={mode === "VERIFY_EMAIL" && 'plinkActive'}>Verify Email</li>
                            <li onClick={()=>setMode("CHANGE_PASSWORD")} className ={mode === "CHANGE_PASSWORD" && 'plinkActive'}>Change Password</li>
                            <li onClick={()=>setMode("DELETE_ACCOUNT")} className ={mode === "DELETE_ACCOUNT" && 'plinkActive'}>Delete Account</li>
                        </ul>
                    </div>
                    
                </div>

                <div className="col-md-9 profileRight">
                    {
                        renderView()
                    }
                    {/* <div className="profileRightTop">
                        Profile
                    </div>
                    <div className="profileForms">
                        
                        <Formik
                        initialValues={initialValues}
                        validationSchema={validationSchema}
                        onSubmit={onSubmit}
                        >
                        <Form>

                            <div className="form-group">
                                <label htmlFor='name'>Name&nbsp;<span className='requiredField'>*</span></label>
                                <Field type="text" className="form-control" id='name' name='name' placeholder="First Name"/>
                                <ErrorMessage name='name' component={TextError}/>
                            </div>
                            <div className="clearfix"></div>   
                                <div className="form-group">
                                <label htmlFor='mobile'>Mobile&nbsp;<span className='requiredField'>*</span></label>
                                <Field type="text" className="form-control" id='mobile' name='mobile' placeholder="Mobile Number"/>
                                <ErrorMessage name='mobile' component={TextError}/>
                            </div>
                            <div className="clearfix"></div>
                            <div className="form-group">
                                <label htmlFor='email'>Email&nbsp;<span className='requiredField'>*</span></label>
                                <Field type="email" className="form-control" id='email' name='email' placeholder="Email"/>
                                <ErrorMessage name='email' component={TextError}/>
                            </div>
                            <div className="clearfix"></div>
                            <div className="qtCourseButtons">
                                <button className="submit">Save</button>
                            </div>
                        </Form>
                        </Formik>
                    </div> */}
                </div>

            </div>
            </div>
        </div>
    </div>
)
}
export default ProfileHomeComponent;