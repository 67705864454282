const TrueFooterComponent = () => {
    return(
        <>
            <div className="qtTrueFooterBottom">
                <div className="container">
                        <div className="row">
                            <ul>
                            
                                
                                <li>
                                    <a href="tel:+919500210134">
                                        <i className="fa fa-phone" aria-hidden="true"></i>&nbsp;&nbsp;+919500210134
                                    </a>
                                </li>
                                <li>
                                <a href="tel:+919500210134">
                                    <i className="fa fa-phone" aria-hidden="true"></i>&nbsp;&nbsp;Talk To Our Technical Exports
                                </a>
                                </li>
                                <li>
                                <a href="tel:+919500210134">
                                    <i className="fa fa-phone" aria-hidden="true"></i>&nbsp;&nbsp;Any Query Related To The Courses
                                </a>
                                </li>
                            </ul>
                        </div>
                </div>
            </div>
        </>
    )
}
export default TrueFooterComponent