import HeaderComponent from "../header"
import FooterComponent from "../footer"
import TrueFooterComponent from "../TrueFooter"
import ContactBannerComponent from "../contact/banner"
import {
    Accordion,
    AccordionItem,
    AccordionItemHeading,
    AccordionItemButton,
    AccordionItemPanel,
} from 'react-accessible-accordion';
import 'react-accessible-accordion/dist/fancy-example.css';
import SeoComponent from "../helmet/SeoComponent";
const PrivacyComponent = () => {
    return(
        <>
            <SeoComponent title="Privacy Policy - Cadbench Online Training Institute" keywords="Best online cad training,Online Cad Courses, Cad classes online,Cad training online,learn cad online" description="Cadbench offers live, Interactive CAD software training. Explore engaging courses to enhance your skills and performance with effective learning methods."/>
            <HeaderComponent isHome="false"/>
            <ContactBannerComponent title="Privacy Policy"/>
            <div>
                <div className="container textAlignLeft oPagesContainer">
                    <div>
                        <p>
                        We value the trust you place in us. That's why we insist upon the highest standards for secure transactions and customer information privacy. Please read the following statement to learn about our information gathering and dissemination practices.
                        </p>
                        <strong>Note</strong>
                        <p>Our privacy policy is subject to change at any time without notice. To make sure you are aware of any changes, please review this policy periodically.</p>
<p>By visiting this Website you agree to be bound by the terms and conditions of this Privacy Policy. If you do not agree please do not use or access our Website.</p>
<p>By mere use of the Website, you expressly consent to our use and disclosure of your personal information in accordance with this Privacy Policy. This Privacy Policy is incorporated into and subject to the Terms and Condition.</p>

                    </div>
                    <Accordion allowZeroExpanded="true">
                    <AccordionItem>
                <AccordionItemHeading>
                    <AccordionItemButton>
                        Terms and conditions
                    </AccordionItemButton>
                </AccordionItemHeading>
                <AccordionItemPanel>
                    <div className="qtTitleAccordian">
                        <p>
                        Cadbench.com ('Website') is an online service operated and managed by Cadbench Company (Cubiksoft Technologies Private Limited' or 'we' or 'us'). In using the Cadbench service, you are deemed to have accepted the terms and conditions of the agreement listed below or as may be revised from time to time ('User Agreement'), which is, for an indefinite period and you understand and agree that you are bound by such terms till the time you access this Website.
                        </p>
                        <p>
                        If you have any queries about the terms and conditions of this User Agreement or have any comments or complaints on or about the Website, please email us at [email protected] We reserve the right to change the terms and conditions of this User Agreement from time to time without any obligation to inform you and it is your responsibility to look through them as often as possible.
                        </p>
                    </div>
                </AccordionItemPanel>
            </AccordionItem>

            <AccordionItem>
                <AccordionItemHeading>
                    <AccordionItemButton>
                    Collection of Personally Identifiable Information and other Information
                    </AccordionItemButton>
                </AccordionItemHeading>
                <AccordionItemPanel>
                    <div className="qtTitleAccordian">
                        <p>
                        When you use our Website, we collect and store your personal information which is provided by you from time to time. Our primary goal in doing so is to provide you a safe, efficient, smooth and customized experience. This allows us to provide services and features that most likely meet your needs, and to customize our Website to make your experience safer and easier. More importantly, while doing so we collect personal information from you that we consider necessary for achieving this purpose.
                        </p>
                        <p>
                        In general, you can browse the Website without telling us who you are or revealing any personal information about yourself. Once you give us your personal information, you are not anonymous to us. Where possible, we indicate which fields are required and which fields are optional. You always have the option to not provide information by choosing not to use a particular service or feature on the Website. We may automatically track certain information about you based upon your behavior on our Website.
                        </p>
                        <p>
                        We use data collection devices such as "cookies" on certain pages of the Website to help analyse our web page flow, measure promotional effectiveness, and promote trust and safety. "Cookies" are small files placed on your hard drive that assist us in providing our services. We offer certain features that are only available through the use of a "cookie".
                        </p>
                        <p>
                        If you choose to buy on the Website, we collect information about your buying behaviour.
                        </p>
                        <p>
                        If you transact with us, we collect some additional information, such as a billing address, a credit / debit card number and a credit / debit card expiration date and/ or other payment instrument details and tracking information from cheques or money orders.
                        </p>
                        <p>
                        If you choose to post messages on our message boards, chat rooms or other message areas or leave feedback, we will collect that information you provide to us. We retain this information as necessary to resolve disputes, provide customer support and troubleshoot problems as permitted by law.
                        </p>
                        <p>
                        If you send us personal correspondence, such as emails or letters, or if other users or third parties send us correspondence about your activities or postings on the Website, we may collect such information into a file specific to you.
                        </p>
                        <p>
                        We collect personally identifiable information (email address, name, phone number, credit card / debit card / other payment instrument details, etc.) from you when you set up a free account with us. While you can browse some sections of our Website without being a registered member, certain activities (such as placing an order) do require registration. We do use your contact information to send you offers based on your previous orders and your interests.
                        </p>
                    </div>
                </AccordionItemPanel>
            </AccordionItem>

            <AccordionItem>
                <AccordionItemHeading>
                    <AccordionItemButton>
                        Questions?
                    </AccordionItemButton>
                </AccordionItemHeading>
                <AccordionItemPanel>
                    <div className="qtTitleAccordian">
                        <p>
                        Questions regarding this statement should be directed to the following address: info@cadbench.com
                        </p>
                        
                    </div>
                </AccordionItemPanel>
            </AccordionItem>


                    </Accordion>
                </div>
            </div>
            <FooterComponent/>
            <TrueFooterComponent/>
        </>
    )
}
export default PrivacyComponent