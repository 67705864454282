import logo from '../../logos.png'
import HeaderComponent from "../header";
import FooterComponent from "../footer";
import { useSelector,useDispatch } from 'react-redux';
import { useState,useEffect } from 'react';
import { Link } from 'react-router-dom';
const CourseComponent = () => {
    const { usersData, isLogin } = useSelector((state) => state.LoginLogout);
    const[courses,setCourses] = useState([]);

    const renderImageView = (title,iconURL) => {
        
        if(iconURL === "" || iconURL === null)
        {
            return(
                <img src={logo} width="100%" alt={title}/>
            )
        }
        else
        {
            return(
                
                <img src={window.IMG_URL+iconURL} width="100%" alt={title}/>
            )
        }
    }

    const getMyCourses = () => {

        if(isLogin === true)
        {
            let URL = window.API_URL+"getUserCourses/"+usersData.id;
                fetch(URL).then((response) => {
                return response.json()
                }).then((res) => {
                   
                    setCourses(res.orders);
                    
                })
    }
    }
    useEffect(() => {
        getMyCourses();
      }, [])


    if(isLogin !== true)
    {
        window.location.replace(window.SITE_URL);
    }
    else
    {
    return(
        <>
            
                <HeaderComponent isHome="false"/>
                <div className="myCoursesHeader">
                    <div className="container">
                        My Courses
                    </div>
                </div>
                <div className="myCourseList">
                    <div className='container'>
                    <div className="row">
                        
                    {courses.length > 0 && (
                        
                          <div>
                           
                        <div>
                        <div className='row'>
                                {courses.map((order,index) => (
                                        <>

                                            {order.orderdetail.map((orderdetail,index) => (
                                                   

                                                                    <div className="col-md-3">
                                                                        <Link to={{pathname:"/mycourses/info/"+orderdetail.ordercourse.slug, state: {orderdetail}}}>
                                                                        
                                                                            <div className="myCourseLists">
                                                                                <div className="myCourseHeader">
                                                                                    {
                                                                                        renderImageView(orderdetail.ordercourse.course_tile,orderdetail.ordercourse.banner_url)
                                                                                    }
                                                                                    {/* <img src={reactjs} alt='cadbench'/> */}
                                                                                </div>
                                                                                <div className='myCourseDetails'>
                                                                                    <h4>{
                                                                                        orderdetail.ordercourse.course_tile
                                                                                        }
                                                                                    </h4>
                                                                                </div>
                                                                            </div>
                                                                            </Link>
                                                                        </div>      


                                                        
                                                        
                                                   
                                            ))}
                                            
                                       </>
                                    ))}

                                    </div>
                        </div>
                 
                  </div>
              )}

                    </div>
                    </div>
                </div>
               
                <FooterComponent/>
        </>
    )
    }
}
export default CourseComponent;