
import ProgramComponent from "../program";
import FooterComponent from "../footer";
import HeaderComponent from "../header";
import useDocumentTitle from '../useDocumentTitle';
import { useLocation } from "react-router-dom";

const AllCourses = () => {
    useDocumentTitle("Cadbench Course Details");
    const queryParameters = new URLSearchParams(window.location.search)
    let s = queryParameters.get("search")
    if(s === null)
        s = "";    
    return(
        <>
        <HeaderComponent isHome="false"/>
        <ProgramComponent searchContent={s}/>
        <FooterComponent/>
        </>
    )

}
export default AllCourses;