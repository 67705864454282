import HeaderComponent from "../header"
import FooterComponent from "../footer"
import TrueFooterComponent from "../TrueFooter"
import ContactBannerComponent from "../contact/banner"
import 'react-accessible-accordion/dist/fancy-example.css';
import SeoComponent from "../helmet/SeoComponent";
const ShippingDelivery = () => {
    return(
        <>
            <SeoComponent title="Shipping Delivery - Cadbench Online Training Institute" keywords="Best online cad training,Online Cad Courses, Cad classes online,Cad training online,learn cad online" description="Cadbench offers live, Interactive CAD software training. Explore engaging courses to enhance your skills and performance with effective learning methods."/>
            <HeaderComponent isHome="false"/>
            <ContactBannerComponent title="Shipping and Delivery Policy"/>
            <div className="container textAlignLeft oPagesContainer">

            <div class="qtMainHead">
                <div class="container">
                    <h4>Digital Access</h4>
                    <div className="clearfix"></div>
                    <p>
                    For Online Courses, the payment related email is sent to users registered mail id. Once, the payment is complete, the user can access the course purchased in our platform.
                    </p>
                    <p>No Shipping involved as delivery is taking place via digital mode.</p>
                        </div>
                        </div>

            
            </div>
            <FooterComponent/>
            <TrueFooterComponent/>
        </>
    )
}
export default ShippingDelivery