import { useState,useEffect } from "react";
import parse from 'html-react-parser';
import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'
const WhyCubicComponent = (props) =>{
    const[training,setTraining] = useState([]);
    

    const getAllData = () => {
        //let URL = window.API_URL+"course/getCourseDetails/1"+props.info.id;
        let URL = window.API_URL+"course/getCourseDetails/1";
        fetch(URL, {
          
          method: "get",
          headers: {
			'TXNTYPE': 'TRAINING',
		},
        })
        .then(response => response.json()
        )
        .then(data => 
          {
            
            setTraining(data)
        }
        )
        .catch(err => console.log(err))
      }

      const setData = () =>{
        if(training.length>0)
        {
            return (
                <div className="row">
                {training.map((course,index) => (
                    <div className="col-md-4" key={index}>
                        <div className="qtWhyContent">
                        <div className="qtWhyHeader">
                            <i className="fa fa-television" aria-hidden="true"></i> {course.title}
                        </div>
                        <div className="qtWhyContents">
                            {parse(course.description)}
                        </div>
                    </div>
                    </div>
                 ))}
                </div>
            )
        }
        else{
            return(
                <div className="row">
                
                {Array.from(Array(3), (e, i) => {
                            return(
                                <div className="col-md-4" key={i}>
                                <div className="qtWhyContent">
                                <div className="qtWhyHeader">
                                <Skeleton variant="circle" width={30} height={30}/><Skeleton variant="text" width={100} count={1}/>
                                </div>
                                <div className="qtWhyContents qtWhyContentsLoader">
                                   <ul>
                                    <li>
                                    <Skeleton variant="text" count={1}/>
                                    </li>
                                    <li>
                                    <Skeleton variant="text" count={1}/>
                                    </li>
                                   </ul>
                                </div>
                            </div>
                            </div>

                            )
                        })
                        }
            </div>
            )
        }
      }
    
      useEffect(() => {
        getAllData()
      }, [])
    return(
        <>
            <div className="qtWhyCubic">
                <div className="container">
                <div className="qtTitle textAlignLeft">
                                    <h2>Why learn {props.info.course_tile} in Cadbench</h2>
                                </div>
                                {
                                    setData()
                                }

                </div>
            </div>
        </>
    )
}
export default WhyCubicComponent