import ContactBannerComponent from "./banner";
import FooterComponent from "../footer";
import HeaderComponent from "../header";
import AddressComponent from "./address";
import AnimationBGComponent from "../Aheader/AnimationBGComponent";
import SeoComponent from "../helmet/SeoComponent";
const ContactComponent = () => {
    return(
        <div>
            <SeoComponent title="Contact Us - Cadbench Online Training Institute" keywords="Best online cad training,Online Cad Courses, Cad classes online,Cad training online,learn cad online" description="Cadbench offers live, Interactive CAD software training. Explore engaging courses to enhance your skills and performance with effective learning methods."/>
            <HeaderComponent isHome="false"/>
            {/* <AnimationBGComponent/> */}
           <ContactBannerComponent title="Contact Us"/>
           {/* <AnimationBGComponent/> */}
           <AddressComponent/>
           <FooterComponent/>
        </div>
    )
}
export default ContactComponent;