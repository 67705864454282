import aboutus from '../../../images/aboutus.jpg';
const WhatWeDoComponent = () => {
    return(
        <>
            <div className="qtreeAboutUs">
                <div className="container">
                <div className="qtMainHeads textAlignLeft">
                    <div className="container">
                    <h4>What we do</h4>
                    <div className='qtHrSmallBorder'>
                    <div className='qtHrLineBorder'></div>
                    </div>
                    </div>
                </div>
                <div className='clearfix'></div>
                    <div className="row">
                        <div className="col-md-3">

                            <div className="qtAboutImg">
                                    <img src={aboutus} width="100%" alt='Cadbench'/>
                            </div>

                        </div>
                        <div className="col-md-9">
                            <div className='qtAboutMainContent'>
                                <p>
                                We offer many Online courses for both enormous associations and individual students. More than 50 courses are accessible on our interactive and intuitive stage. A portion of the famous training is AutoCad, Catia, Sketchup, and 3ds Max.</p>
                                <p>Our top affirmed educators with more than 10 years of involvement in the same field are prepared to take you to a higher level and increment your profession open doors. We are very enthusiastic and focused on what we do. It shows in our instructional exercises which are predictable with important data and planned decisively to be gained effortlessly.</p>
                                <p>Candidates can be guaranteed 100% Job Assistance. We take extraordinary endeavors to guarantee that learners land positions that are ideally suited to their abilities and will assist them with progressing in their vocations.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}
export default WhatWeDoComponent;