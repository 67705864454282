// import Accordion from '@mui/material/Accordion';
// import AccordionSummary from '@mui/material/AccordionSummary';
// import AccordionDetails from '@mui/material/AccordionDetails';
// import Typography from '@mui/material/Typography';
// import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {
    Accordion,
    AccordionItem,
    AccordionItemHeading,
    AccordionItemButton,
    AccordionItemPanel,
} from 'react-accessible-accordion';
import 'react-accessible-accordion/dist/fancy-example.css';
import { useState,useEffect } from 'react';
import parse from 'html-react-parser';
import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'
const ExplainComponent = (props) => {
    const[cert,setCert] = useState([]);

    const getAllData = () => {
        let URL = window.API_URL+"course/getCourseDetails/"+props.info.id;
        fetch(URL, {
          
          method: "get",
          headers: {
			'TXNTYPE': 'CERTIFICATIONCOURSE',
		},
        })
        .then(response => response.json()
        )
        .then(data => 
          {
            
            setCert(data)
        }
        )
        .catch(err => console.log(err))
      }

      const setData = () => {
        if(cert.length > 0)
        {
            return(
                cert.map((course,index) => (

                    <AccordionItem key={index}>
                    <AccordionItemHeading>
                        <AccordionItemButton>
                            {course.title}
                        </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                        <div className="qtTitleAccordian">
                            <ul>
                                {parse(course.description)}
                            </ul>
                        </div>
                    </AccordionItemPanel>
                    </AccordionItem>    

             ))
            )
        }
        else{
            return (
                <div className='sPadding'>
                <Skeleton variant="text" count={10}/>
           </div>
            )
        }
      }
    
      useEffect(() => {
        getAllData()
      }, [])
    return(
        <>
            <div className="qtExplain">

                    <div className="qtTitle">
                    <h2>{props.info.course_tile} Certification Course Details</h2>
                    </div>
                    <div className='clearfix'></div>
                    <div className='clearfix'></div>

                <div className="container text_align_left">

                <Accordion allowZeroExpanded="true">
                {
                    setData()
                }
                </Accordion>

               
                </div>
            </div>
        </>
    )   
}
export default ExplainComponent