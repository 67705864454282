import React from 'react'
import { useState,useEffect } from 'react';
import CourseEncModal from "./courseenquiry"
import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'
const TrainingTiming=(props)=>{
    const [modalShow, setModalShow] = React.useState(false);

    const[time,setTime] = useState([]);
    
    const getBatchTime = () => {
        let URL = window.API_URL+"course/getCourseDetails/"+props.info.id;
        fetch(URL, {
          method: "get",
          headers: {
			'TXNTYPE': 'BATCH_TIME',
		},
        })
        .then(response => {
            return response.json()
        }
        )
        .then(data => 
          {
            
            setTime(data)
        }
        )
        .catch(err => console.log(err))
      }

    const setData = () => {
        
        if(time.length > 0)
        {
            return(<table>
                <tbody>
            {time.map((data,index) => (
                    <tr key={index}>
                    <td>{data.batch_date}</td>
                    <td>{data.batch_days}</td>
                    <td>{data.batch}</td>
                    <td>{data.time}</td>
                    </tr>
             ))}
             </tbody>
            </table>
            )
        }
        else
        {
            return(
            <table>
                <tbody>
            {Array.from(Array(3), (e, i) => {
                return(
                        <tr key={i}>
                                    <td><Skeleton  count={1} width={200}/></td>
                                    <td><Skeleton  count={1} width={200}/></td>
                                    <td><Skeleton  count={1} width={200}/></td>
                                </tr>

                )
              })
              }
              </tbody>
              </table>
              )
        }
        
    }  

  useEffect(() => {
    getBatchTime()
  }, [])

    return(
        <>
            <div className="qtTrainingTime">
                <div className="container">
                            <div className="qtTitle">
                                    <h2>Get Trained at your Flexible timmings
</h2>
                                </div>
                             <div className="qtTrainingTimeCont">
                             CubikCadd provides {props.info.course_tile} Training at Your Fexible Timings by our
Industrial Expert Trainers.
    
                            </div>   
                </div>
                <div className="qtDesignation">
                <div className="container">
                    <div className="row">
                        <div className="col-md-4">
                            <h3>Flexible Batches For You</h3>
                        </div>
                        <div className="col-md-8">
                            <div className="qtTimingContent">
                                
                                <ul>
                                    <li className="timingActive">On-line Training</li>
                                    <li>One to One Training</li>
                                </ul>
                            </div>
                        </div>
                    </div>

                    <div className="row">
                        <div className="qtTiming">
                        <div className="qtTimingBatch" onClick={() => setModalShow(true)}>
                            <span>
                            <i className="fa fa-calendar" aria-hidden="true"></i>can't find a batch?
                            </span>
                        </div>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-md-4">
                            <h3>Available Batches For You</h3>
                        </div>
                        <div className="col-md-8">
                            <div className="qtTimingBatchContent">
                                {
                                    setData()
                                }
                            {/* {time.length > 0 && (
                            <table>
                            {time.map((data,index) => (
                                    <tr key={index}>
                                    <td>{data.batch_date}</td>
                                    <td>{data.batch_days}</td>
                                    <td>{data.batch}</td>
                                    <td>{data.time}</td>
                                    </tr>
                             ))}
                            </table>
                         )}
                              
                               <table>
                                <tr>
                                    <td><Skeleton  count={1} width={200}/></td>
                                    <td><Skeleton  count={1} width={200}/></td>
                                    <td><Skeleton  count={1} width={200}/></td>
                                </tr>
                                </table>      */}

                            </div>
                        </div>
                    </div>

                    

                </div>
            </div>
            </div>
            {/* <BannerBottom/> */}
            <CourseEncModal show={modalShow}
            onHide={() => setModalShow(false)} captchaRef={props.captchaRef}/>
        </>
    )
}
export default TrainingTiming