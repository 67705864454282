import apply from '../../images/apply.gif'
import ApplyComponent from "./apply";
import {
    Accordion,
    AccordionItem,
    AccordionItemHeading,
    AccordionItemButton,
    AccordionItemPanel,
} from 'react-accessible-accordion';
import 'react-accessible-accordion/dist/fancy-example.css';
import parse from 'html-react-parser';
import { useState,useEffect } from 'react';
import LoadingComponent from '../loading';
import { toast} from 'react-toastify';
import { Formik,Form,Field,ErrorMessage } from 'formik';
import * as Yup from 'yup'
import TextError from '../error/TextError';
import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'
const CurriculamComponent = (props) => {
    const [isLoading,setLoading] = useState(false);
    const[curriculam,setCurriculam] = useState([]);

    const initialFormValues = {
        name:'CUBIKCADD',
        email:'',
        mobile:''
      }
      const phoneRegExp = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/ 
      const emailRegExp = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/
      const validationSchema = Yup.object(
        {
            email:Yup.string().matches(emailRegExp,'Invalid email format!').required('Required!'),
            mobile: Yup.string().required('Required').matches(phoneRegExp, 'Mobile Number is not valid').min(10),
        });

    const handleSubmit = async(values) => 
    {
        setLoading(true)
        const token = await props.captchaRef.current.executeAsync(); 
        var myHeaders = new Headers();
        const datas = new FormData();
        datas.append("name", values.name);
        datas.append("email", values.email);
        datas.append("mobile", values.mobile);
        datas.append("enquiry_for","LIKE_CURRICULAM");
        datas.append("g-recaptcha-response",token);
        let url = window.API_URL+"enquiry";
        fetch(url, {
            method: "POST",
            body: datas,
            headers: myHeaders,
          })
          .then(response => response.json()
          )
          .then(data => 
            {
              props.captchaRef.current.reset();
              if(data[0]['data']['success'] === 1)
              {
                toast.success("Thanking You!", {
                    position: toast.POSITION.TOP_RIGHT
                  });
                  values.mobile = ""
                  values.email = ""
              }
              else{
                toast.error("Unable to submit! Please Try Again!", {
                    position: toast.POSITION.TOP_RIGHT
                  });
              }
              setLoading(false)
          }
          )
          .catch(err => {
            props.captchaRef.current.reset();
            toast.error("Unable to submit! Please Try Again!", {
                position: toast.POSITION.TOP_RIGHT
              });
                setLoading(false)
          })
    }

    const getAllData = () => {
        let URL = window.API_URL+"course/getCourseDetails/"+props.info.id;
        fetch(URL, {
          
          method: "get",
          headers: {
			'TXNTYPE': 'CURRICULAM',
		},
        })
        .then(response => response.json()
        )
        .then(data => 
          {
            setCurriculam(data)
        }
        )
        .catch(err => console.log(err))
      }

    const setData = () => {
        if(curriculam.length > 0)
        {
            return(
                curriculam.map((course,index) => (

                    <AccordionItem key={index}>
                    <AccordionItemHeading>
                        <AccordionItemButton>
                            {course.title}
                        </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                        <div className="qtTitleAccordian">
                            <ul>
                                {parse(course.description)}
                            </ul>
                        </div>
                    </AccordionItemPanel>
                    </AccordionItem>    

             ))
            )
        }
        else
        {
                return(
                    <div className='sPadding'>
                         <Skeleton variant="text" count={10}/>
                    </div>
                )
        }
    }  
    
      useEffect(() => {
        getAllData()
      }, [])

    return(
        <>  
        
        <LoadingComponent isShow = {isLoading}/>  
            <div className="qtCurriculam">
                <div className="container">
                    <div className="row">
                        <div className="col-md-8">

                        <div className="qtTitle textAlignLeft">
                        <h2>{props.title} Course Curriculam</h2>
                        </div>   

                        <div className="qtSubContent texAlignLeft">
                        <p>{props.description}</p>
                    </div>
                    <div className="qtCourseText qtSubContent texAlignLeft">

                    <Accordion allowZeroExpanded="true">
                        {
                            setData()
                        }
                    </Accordion>

                    {/* {curriculam.length > 0 && (
                            <Accordion>
                            {curriculam.map((course,index) => (

                                    <AccordionItem>
                                    <AccordionItemHeading>
                                        <AccordionItemButton>
                                            {course.title}
                                        </AccordionItemButton>
                                    </AccordionItemHeading>
                                    <AccordionItemPanel>
                                        <div className="qtTitleAccordian">
                                            <ul>
                                                {parse(course.description)}
                                            </ul>
                                        </div>
                                    </AccordionItemPanel>
                                    </AccordionItem>    

                             ))}
                            </Accordion>
                         )} */}

        </div>
        </div>

                        <div className="col-md-4">
                            <ApplyComponent captchaRef={props.captchaRef}/>
                        </div>
                    </div>
                     <div className='qtApplyHeader'>       
                     <div className="qtApplyCurriculam">
                     <Formik
                                initialValues={initialFormValues}
                                validationSchema={validationSchema}
                                onSubmit={handleSubmit}
                                >
                                    <Form>
                        <div className="row">
                            <div className="col-md-8">
                            <div className="qtTitle textAlignLeft">
                            <h2>Like the curriculam? Get Started</h2>    
                            </div>  
                            <div className="clearfix"></div>
                            <div className="clearfix"></div>
                            <div className="clearfix"></div>
                            <div className="row">
                                
                                        <div className="col-md-6 textAlignLeft">
                                            <label>Phone Number</label>
                                            <Field type="text" 
                                            className="form-control" 
                                            id='mobile'
                                            name='mobile'
                                            maxLength="12" 
                                            placeholder="Mobile Number"/>
                                            <ErrorMessage name='mobile' component={TextError}/>
                                        </div>
                                        <div className="col-md-6 textAlignLeft">
                                            <label>Email ID</label>
                                            <Field type="email" 
                                            className="form-control" 
                                            id='email'
                                            name='email'
                                            placeholder="Name"/>
                                            <ErrorMessage name='email' component={TextError}/>
                                        </div>
                                    
                            </div>    
                            </div>  
                            <div className="col-md-4">
                               <img src={apply} alt="Cadbench"/>
                            <div className="qtCourseButtonsFull"><button className="submit">Apply Now</button></div>
                                </div>  
                        </div>  
                        </Form>
                                </Formik>
                    </div>  
                    </div>     

                </div>
            </div>
        </>        
    )
}
export default CurriculamComponent;