import FooterComponent from "../footer";
import HeaderComponent from "../header";
import emailVerfied from "../../images/verify_emails.gif"
import { useSearchParams } from "react-router-dom";
import { useSelector,useDispatch } from 'react-redux';
import { login } from "../actions";
const EmailSuccess = () =>{
    const loginState = useSelector((state)=>state.LoginLogout);
    const [searchParams, setSearchParams] = useSearchParams();
    if(loginState.isLogin === true)
    {
            loginState.usersData['email_verified_at'] = Date().toLocaleString();
    }
    return(
        <>
            <HeaderComponent isHome="false"/>
            <div className="cadEmail">
                <div className="cadEmails">
                    <div className="cadEmailsMain">
                <div className="cadEmailStatus">
                    Account Activated
                </div>
                <div className="cadEmailIcon">
                    <img src={emailVerfied} alt="Cadbench"/>
                </div>
                <div className="cadEmailHeading">
                    Hello {searchParams.get("verify")},
                </div>    
                <div className="cadEmailContents">
                    Thank you, your email has been verified, your account is now fully active.
                </div>
                </div>
                </div>
            </div>
           <FooterComponent/>
        </>
    )
}
export default EmailSuccess