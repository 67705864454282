import { Link } from "react-router-dom";
const TalkComponent = () =>{
    return(
        <div className="qtTalkComponent">
            <div className="container">
                <div className="row">
                    <div className="col-md-10 ">
                        <div className="qtTalkContent">
                        Learn From Experts & Transform Your Career
                        </div>
                    </div>
                    <div className="col-md-2">
                        <div className="qtTalkLink">
                            <Link>Talk To Our Export</Link>
                            </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default TalkComponent;