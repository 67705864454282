import Form from 'react-bootstrap/Form';
import facebook from '../../../images/facebook_new.png'
import linkedin from '../../../images/linkedin_new.png'
import twiiter from '../../../images/twitter_new.png'
import instagram from '../../../images/insta_new.png'
import { useRef, useState } from 'react';
import LoadingComponent from '../../loading';
import { toast} from 'react-toastify';
import { Formik,Form as FormikForm,Field,ErrorMessage } from 'formik';
import * as Yup from 'yup';
import TextError from '../../error/TextError';
import ReCAPTCHA from "react-google-recaptcha";


const AddressComponent = () => {
    const[isLoading,setLoading] = useState(false);
    const recaptchaRef = useRef(null);
    const initialFormValues = {
        name:'',
        email:'',
        mobile:'',
        message:'',
        recaptcha:'',
      }
      const phoneRegExp = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/ 
      const emailRegExp = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/
      const validationSchema = Yup.object(
        {
            name:Yup.string().required('Required!'),
            // email:Yup.string().email('Invalid email format!').required('Required!'),
            email:Yup.string().matches(emailRegExp,'Invalid email format!').required('Required!'),
            mobile: Yup.string().required('Required').matches(phoneRegExp, 'Mobile Number is not valid').min(10),
            message:Yup.string().required('Required'),
            //recaptcha:Yup.string().required('Required'),
        });  

//    const captchaOnChange = (response) => {
//             initialFormValues.recaptcha = response;
//             console.log("Form Data",initialFormValues)
//             console.log("Captcha Changed",response);
//    }     
   
  const handleSubmit = async(values) => 
  {

    //console.log("Form Data",initialFormValues)
     const token = await recaptchaRef.current.executeAsync(); 
      setLoading(true)
      var myHeaders = new Headers();
      const datas = new FormData();
      datas.append("name", values.name);
      datas.append("email", values.email);
      datas.append("mobile", values.mobile);
      datas.append("message", values.message);
      datas.append("enquiry_for","CONTACT_US");
    //   datas.append("g-recaptcha-response",recaptchaRef.current.getValue());
    datas.append("g-recaptcha-response",token);
      let url = window.API_URL+"enquiry";
      fetch(url, {
          "crossDomain": true,
          method: "POST",
          body: datas,
          headers: myHeaders,
          "processData": false,
          "contentType": false,
        })
        .then(response => response.json()
        )
        .then(data => 
          {
            recaptchaRef.current.reset();
            setLoading(false)
            if(data[0].data.success === 1)
            {
                toast.success("Thanking You!", {
                    position: toast.POSITION.TOP_RIGHT
                  });
                values.name = ""
                values.email = ""
                values.mobile =""
                values.message = ""
                return
            }
            if(data.success === false)
            {
                if(data.message === "Validation Error.")
                {
                    
                    if(JSON.stringify(data).includes('g-recaptcha-response'))
                    {
                        toast.error(data.data['g-recaptcha-response'][0], {
                            position: toast.POSITION.TOP_RIGHT
                          });
                    }
                    else{
                        toast.error(data.message, {
                            position: toast.POSITION.TOP_RIGHT
                          });
                    }
                   
                }
                return
            }
            
                toast.error("Unable to submit! Please Try Again!", {
                    position: toast.POSITION.TOP_RIGHT
                  });
            
          
            
            
        }
        )
        .catch(err => {
            recaptchaRef.current.reset();
            toast.error("Unable to submit! Please Try Again!", {
                position: toast.POSITION.TOP_RIGHT
              });
              setLoading(false)
              
        })
  }

    return(
        <>
            
            <LoadingComponent isShow={isLoading}/>
            <div>
                <div className="qtAddress">
                    <div className="container qtContactContainer" style={{backgroundColor: "#FFFFFF"}}>
                        <div className="row">
                            <div className="col-md-5">
                            <div className="qtAddresss text_align_left">
                            <div className="qtContactHeading">
                                        <h3>Corporate Office</h3>
                                    </div>
                                    <p>
                                    Cadbench.com,
                                    22,23 Sarojini Street, First Floor,
                                    Ramnagar,
                                    Coimbatore,
                                    Tamilnadu
                                    India-641 009
                                    
                                    </p>

                                    <div className="qtContactHeading">
                                        <h3>Branch Office</h3>
                                    </div>
                                    <p>
                                    Mahaveer Towers, No 126, 2nd Floor, Sathy Main Rd, 
                                    Saravanampatti, 
                                    <span className='cadHAddress'> Coimbatore, </span>
                                    Tamil Nadu 641035 
                                    </p> 

                                    <p>
                                    3/87A,First floor,OMR, opp. to SATHYABAMA UNIVERSITY, 
                                    Semmancheri, <span className='cadHAddress'> Chennai, </span>
                                    Tamil Nadu 600119
                                    </p> 

                                    <p>
                                    London - UK,
                                    81-A High Street,
                                    Thornton Heath, CR7 8RY,
                                    <span className='cadHAddress'> London</span>
                                    </p> 

                                    <p>
                                    California -USA,
                                    5050 Hacienda Dr. #1926,
                                    Dublin. California. 94568.
                                    <span className='cadHAddress'> United States</span>
                                    </p>

                                    <p>
                                    <span className='cadHAddress'>Kuwait, </span>
                                    Fahaheel Block 7,
                                    Near Yall Mall - 13001
                                    </p>

                                    <div className='clearfix'>

                                    </div>
                                    <h5>Contact: info@cadbench.com</h5>
                                    <div className='clearfix'>

                                    </div>
                                    <div className='clearfix'>

                                    </div>
                                <div className='qtContactSocial'>
                                    
                                 <div className='qtSocial'>
                                 <a href="https://www.facebook.com/people/Cadbench/61554921329711/" target='_blank' rel="noreferrer">
                                        <img src={facebook} alt='Facebook' width='5%'/>
                                        &nbsp;&nbsp;Facebook
                                        </a>
                                  </div>   
                                <div className='clearfix'></div>
                                  <div className='qtSocial'>
                                    <a href="https://www.linkedin.com/company/cadbench" target='_blank' rel="noreferrer">
                                        <img src={linkedin} alt='Linkedin' width='5%'/>
                                        &nbsp;&nbsp;Linkedin
                                        </a>
                                  </div>   

                                  <div className='clearfix'></div>
                                  <div className='qtSocial'>
                                  <a href="https://twitter.com/CubikCadd" target='_blank' rel="noreferrer"> <img src={twiiter} alt='Twitter' width='5%'/>
                                        &nbsp;&nbsp;Twitter
                                        </a>
                                  </div>  

                                  <div className='clearfix'></div>
                                  <div className='qtSocial'>
                                  <a href="https://www.instagram.com/cad_bench/" target='_blank' rel="noreferrer"> <img src={instagram} alt='Twitter' width='5%'/>
                                        &nbsp;&nbsp;Instagram
                                        </a>
                                  </div>  

                                </div>           

                                </div>
                            </div>
                            <div className="col-md-7">
                                <div className="qtAddressMap">
                                
                                <iframe title='cadMap' src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3916.367153593039!2d76.96327607371515!3d11.011055389152329!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3ba859868c140e99%3A0x6819a6800c35053!2sQtree%20Technologies%20-%20AWS%7CAzure%7CGCP%7CDevOps%7CSelenium%7CJava%20%7CPython%20%7CData%20Science-AI%2FML-Full%20Stack-%20Placement!5e0!3m2!1sen!2sin!4v1684647077950!5m2!1sen!2sin" width="100%" height="250" style={{border:"0"}} allowFullScreen="" loading="lazy" referrerPolicy="no-referrer-when-downgrade"></iframe>
                                </div>
                                <div className="qtContactForm">
                                    <div className="qtContactHeading">
                                        <h3>Any Other Queries</h3>
                                    </div>
                                    <div className='qtContactForms'>

                                <Formik
                                    initialValues={initialFormValues}
                                    validationSchema={validationSchema}
                                    onSubmit={handleSubmit}
                                >       
                                    <FormikForm>
                                    <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                    <Form.Label>Name</Form.Label>
                                    <Field
                                        type="text"
                                        id='name'
                                        name='name'
                                        className='form-control'
                                        placeholder="Mark Jack"
                                    />
                                    <ErrorMessage name='name' component={TextError}/>
                                     </Form.Group>
                                     <div className='row'>
                                        <div className='col-md-6'>
                                        <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                    <Form.Label>Email address</Form.Label>
                                    <Field
                                        type="email"
                                        id='email'
                                        name='email'
                                        className='form-control'
                                        placeholder="mark@cadbench.com"
                                    />
                                    <ErrorMessage name='email' component={TextError}/>
                                     </Form.Group>
                                        </div>
                                        <div className='col-md-6'>
                                        <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                    <Form.Label>Mobile Number</Form.Label>
                                    <Field
                                        type="text"
                                        id='mobile'
                                        name='mobile'
                                        maxLength="12" 
                                        className='form-control'
                                        placeholder="99999 99999"
                                    />
                                    <ErrorMessage name='mobile' component={TextError}/>
                                     </Form.Group>
                                        </div>  
                                     </div>
                                     <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
                                        <Form.Label>Message</Form.Label>
                                        <Field as="textarea" 
                                        rows={5} 
                                        id='message'
                                        name='message'
                                        className='form-control'
                                       />
                                       <ErrorMessage name='message' component={TextError}/>
                                    </Form.Group>
                                    <Form.Group>
                                    {/* <Recaptcha
                                        sitekey="6Le-w4UnAAAAABClFFYXcDOQDubzH5aWeXKMNsz_"
                                        verifyCallback={(response)=>captchaOnChange(response)}
                                        onloadCallback={() => { console.log("done loading!"); }}
                                        /> */}

                                    {/* <Field
                                        name = 'recaptcha'
                                        id ='recaptcha'
                                        as={ReCAPTCHA}
                                        ref={recaptchaRef}
                                        sitekey="6Le-w4UnAAAAABClFFYXcDOQDubzH5aWeXKMNsz_"
                                        onChange = {(response)=>captchaOnChange(response)}
                                    />     */}
                                    <ReCAPTCHA
                                        ref={recaptchaRef}
                                        className='googleRecaptcha'
                                        size="invisible"
                                        sitekey={process.env.REACT_APP_RECAPTCHA_KEY}
                                        // onChange = {(response)=>captchaOnChange(response)}
                                        
                                    />
                                    {/* <ErrorMessage name='recaptcha' component={TextError}/> */}
                                    <div className='clearfix'></div>
                                    </Form.Group>

                                    <button className="qtLoginBtn" >Submit</button>
                                     
                                    </FormikForm>
                                    </Formik>  
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
export default AddressComponent;