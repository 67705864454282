import Modal from 'react-bootstrap/Modal';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import courseimg from '../../images/course.gif'
import { toast} from 'react-toastify';
import { Formik,Field,Form as FormikForm,ErrorMessage } from "formik";
import * as Yup from 'yup'
import TextError from "../error/TextError";
import LoadingComponent from "../loading";
import { useState } from 'react';
const CourseEncModal = (props) =>{
  const[isLoading,setLoading] = useState(false);
  const initialFormValues = {
    date:'',
    email:'',
    mobile:''
  }
  const phoneRegExp = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/ 
  const emailRegExp = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/
  const validationSchema = Yup.object(
    {
       date:Yup.string().required('Required!'),
        email:Yup.string().matches(emailRegExp,'Invalid email format!').required('Required!'),
        mobile: Yup.string().required('Required').matches(phoneRegExp, 'Mobile Number is not valid').min(10),
    });

    const handleSubmit = async(values) => 
  {
      setLoading(true)
      const token = await props.captchaRef.current.executeAsync(); 
      var myHeaders = new Headers();
      const datas = new FormData();
      datas.append("date", values.date);
      datas.append("email", values.email);
      datas.append("mobile", values.mobile);
      datas.append("enquiry_for","CANT_FIND_BATCH");
      datas.append("g-recaptcha-response",token);
      let url = window.API_URL+"enquiry";
      fetch(url, {
          "crossDomain": true,
          method: "POST",
          body: datas,
          headers: myHeaders,
          "processData": false,
          "contentType": false,
        })
        .then(response => response.json()
        )
        .then(data => 
          {
            props.captchaRef.current.reset();
            if(data[0]['data']['success'] === 1)
            {
                toast.success("Thanking You!", {
                    position: toast.POSITION.TOP_RIGHT
                  });
                values.name=""
                values.email = ""
                values.mobile = ""
            }
            else{
                toast.error("Unable to submit! Please Try Again!", {
                    position: toast.POSITION.TOP_RIGHT
                  });
            }
            setLoading(false)
        }
        )
        .catch(err => {
            props.captchaRef.current.reset();
            toast.error("Unable to submit! Please Try Again!", {
                position: toast.POSITION.TOP_RIGHT
              });
              setLoading(false)
        })
  }

    return(
      <>
        <LoadingComponent isShow = {isLoading}/>  
     
        <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      
      <Modal.Body className='show-grid'>
        <Container>
            <Row>
                <Col xs={12} md={6} className='qtModalAppLeft'>
                    <div>
                        <img src={courseimg} alt="Cadbench" width="100%"/>
                    </div>
                </Col>
                <Col xs={12} md={6}>
                        <div className='qtModalAppRight'>
                        <div className="modal-title h4">Tell Us Your Preferred Starting Date</div>
                            <div className='clearfix'></div>
                            <Formik
                        initialValues={initialFormValues}
                        validationSchema={validationSchema}
                        onSubmit={handleSubmit}
                    >
                                <FormikForm>
                                <div className="form-group">
                                  <label>Preferred Date</label>
                                  <Field type="date" 
                                  className="form-control" 
                                  id='date'
                                  name='date'
                                  placeholder="Date"/>
                                  <ErrorMessage name='date' component={TextError}/>
                              </div>
                              <div className="clearfix"></div>
                              <div className="form-group">
                              <label>Email</label>
                                <Field type="email"
                                className="form-control" 
                                id='email'
                                name='email'
                                placeholder="Email"/>
                                <ErrorMessage name='email' component={TextError}/>
                            </div>
                            <div className="clearfix"></div>
                            <div className="form-group">
                            <label>Mobile</label>
                            <Field type="text"  
                            className="form-control" 
                            id='mobile'
                            name='mobile'
                            maxlength="12" 
                            placeholder="Mobile Number"/>
                            <ErrorMessage name='mobile' component={TextError}/>
                        </div>
                        <div className="clearfix"></div>
                                <div className="qtCourseButtons">
                                <button className="submit">Submit</button>
                                &nbsp;&nbsp;<button type="button" className="closeModal" onClick={props.onHide}>Close</button>
                                
                            </div>
                                </FormikForm>
                                </Formik>
                        </div>
                </Col>
            </Row>
        </Container>
      </Modal.Body>
     
    </Modal>
    </>
    )
}
export default CourseEncModal