import React from 'react';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { Provider } from 'react-redux';
// import { GoogleOAuthProvider } from '@react-oauth/google';
 //import store from './components/store/index'
 import { createRoot } from 'react-dom/client';
 import { PersistGate } from 'redux-persist/integration/react'
 //import persistor from './components/store/index'
 import { createStore } from 'redux';
 import { persistStore, persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage' // default
import rootReducers from './components/reducers';
import autoMergeLevel2 from 'redux-persist/lib/stateReconciler/autoMergeLevel2'
import { encryptTransform } from 'redux-persist-transform-encrypt';
import { HelmetProvider } from 'react-helmet-async';
import "react-toastify/dist/ReactToastify.css";

window.SITE_URL = "https://cadbench.com/";
window.API_URL = "https://cadbench.com/cadadminapi/public/api/";
window.IMG_URL = "https://cadbench.com/cadadminapi/public/uploads/";


//  window.SITE_URL = "https://cadbench.com/sandbox/";

// window.SITE_URL = "https://cadbench.com/sandbox/";
// window.API_URL = "https://cadbench.com/sandbox/cadadminapi/public/api/";
// window.IMG_URL = "https://cadbench.com/sandbox/cadadminapi/public/uploads/";

//window.SITE_URL = "http://localhost:3000/";
// window.API_URL = "http://localhost:8000/api/";
// window.IMG_URL = "http://localhost:8000/uploads/";

//const root = ReactDOM.createRoot(document.getElementById('root'));
//  window.SITE_URL = "https://cadbench.com/sandbox/";
//  window.API_URL = "https://cadbench.com/cadadminapi/public/api/";
//  window.IMG_URL = "https://cadbench.com/cadadminapi/public/uploads/";
// window.SITE_URL = "http://localhost:3000/sandbox/";
// window.API_URL = "http://localhost:8000/api/";
// window.IMG_URL = "http://localhost:8000/uploads/";
const container = document.getElementById('root');
const root = createRoot(container);

const persistConfig = {
  key: 'root',
  storage,
  stateReconsiler:autoMergeLevel2,
  transforms: [
    encryptTransform({
      secretKey: 'CAD_BENCH_SEC_KEY_9898',
      onError: function (error) {
        // Handle the error.
      },
    }),
  ],
}

const persistedReducer = persistReducer(persistConfig, rootReducers)  

let store = createStore(persistedReducer,window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__());
let persistor = persistStore(store);

const helmetContext = {};
root.render(
  // <GoogleOAuthProvider clientId="661776952066-qb1g4nnpjjt9bh6s2l43fmrfhm0ntr46.apps.googleusercontent.com" store={store}>
  <HelmetProvider context={helmetContext}>
  <Provider store={store}>
    <PersistGate loading={null} persistor={persistor}>
      {/* <React.StrictMode> */}
        <App />
      {/* </React.StrictMode> */}
    </PersistGate>
  </Provider>
  </HelmetProvider>
  // </GoogleOAuthProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
