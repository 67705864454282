const CountComponent = () => {
    return(
        <>
            <div className="qtCountMain">
                <div className="container">
                    <div className="qtCountMains">
                        <h3>Cadbench's Achievement</h3>
                    </div>
                </div>
            </div>
            <div className="qtCountsMain">
            <div className='row'>
                        <div className='col-md-3 qtBorderRight'>
                            <div className='qtBanBotContent'>
                                <div className="allban">
                                    <div className="ban_featleft"> 
                                       <i className="fa fa-book"></i>
                                    </div>
                                    <div className="ban_featright">
                                        <p><span>150+</span> Courses</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='col-md-3 qtBorderRight'>
                            <div className='qtBanBotContent'>
                            <div className="allban">
                                    <div className="ban_featleft"> 
                                    <i className="fa fa-graduation-cap" aria-hidden="true"></i>
                                    </div>
                                    <div className="ban_featright">
                                        <p><span>1000+</span> Learners</p>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className='col-md-3 qtBorderRight'>
                            <div className='qtBanBotContent'>
                            <div className="allban">
                                    <div className="ban_featleft"> 
                                    <i className="fa fa-globe" aria-hidden="true"></i>
                                    </div>
                                    <div className="ban_featright">
                                        <p><span>10+</span> Countries</p>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className='col-md-3'>
                            <div className='qtBanBotContent'>
                            <div className="allban">
                                    <div className="ban_featleft"> 
                                    <i className="fa fa-star" aria-hidden="true"></i>
                                    </div>
                                    <div className="ban_featright">
                                        <p><span>4.9</span> Reviews</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
            </div>

        </>
    )
}
export default CountComponent;